

.Overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: var(--background);
}

.GuestIntro {
  position: relative;
}
