.Flag {
  box-shadow: var(--profile-flag-shadow);
}

.bg-AD {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -10px;
}


.bg-AE {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -10px;
}


.bg-AF {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -46px;
}


.bg-AG {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -46px;
}


.bg-AL {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -10px;
}


.bg-AM {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -46px;
}


.bg-AO {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -82px;
}


.bg-AR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -82px;
}


.bg-AT {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -82px;
}


.bg-AU {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -10px;
}


.bg-AZ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -46px;
}


.bg-BA {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -82px;
}


.bg-BB {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -118px;
}


.bg-BD {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -118px;
}


.bg-BE {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -118px;
}


.bg-BF {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -118px;
}


.bg-BG {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -10px;
}


.bg-BH {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -46px;
}


.bg-BI {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -82px;
}


.bg-BJ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -118px;
}


.bg-BN {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -154px;
}


.bg-BO {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -154px;
}


.bg-BR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -154px;
}


.bg-BS {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -154px;
}


.bg-BT {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -154px;
}


.bg-BW {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -190px;
}


.bg-BY {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -190px;
}


.bg-BZ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -190px;
}


.bg-CA {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -190px;
}


.bg-CD {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -190px;
}


.bg-CF {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -10px;
}


.bg-CG {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -46px;
}


.bg-CH {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -82px;
}


.bg-CI {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -118px;
}


.bg-CL {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -154px;
}


.bg-CM {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -190px;
}


.bg-CN {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -226px;
}


.bg-CO {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -226px;
}


.bg-CR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -226px;
}


.bg-CU {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -226px;
}


.bg-CV {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -226px;
}


.bg-CY {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -226px;
}


.bg-CZ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -10px;
}


.bg-DE {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -46px;
}


.bg-DJ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -82px;
}


.bg-DK {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -118px;
}


.bg-DM {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -154px;
}


.bg-DO {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -190px;
}


.bg-DZ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -226px;
}


.bg-EC {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -262px;
}


.bg-EE {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -262px;
}


.bg-EG {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -262px;
}


.bg-ER {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -262px;
}


.bg-ES {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -262px;
}


.bg-ET {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -262px;
}


.bg-FI {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -262px;
}


.bg-FJ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -10px;
}


.bg-FM {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -46px;
}


.bg-FR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -82px;
}


.bg-GA {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -118px;
}


.bg-GB {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -154px;
}


.bg-GD {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -190px;
}


.bg-GE {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -226px;
}


.bg-GH {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -262px;
}


.bg-GM {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -298px;
}


.bg-GN {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -298px;
}


.bg-GQ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -298px;
}


.bg-GR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -298px;
}


.bg-GT {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -298px;
}


.bg-GW {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -298px;
}


.bg-GY {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -298px;
}


.bg-HN {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -298px;
}


.bg-HR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -10px;
}


.bg-HT {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -46px;
}


.bg-HU {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -82px;
}


.bg-ID {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -118px;
}


.bg-IE {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -154px;
}


.bg-IL {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -190px;
}


.bg-IN {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -226px;
}


.bg-IQ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -262px;
}


.bg-IR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -298px;
}


.bg-IS {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -334px;
}


.bg-IT {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -334px;
}


.bg-JM {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -334px;
}


.bg-JO {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -334px;
}


.bg-JP {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -334px;
}


.bg-KE {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -334px;
}


.bg-KG {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -334px;
}


.bg-KH {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -334px;
}


.bg-KI {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -334px;
}


.bg-KM {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -370px;
}


.bg-KN {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -370px;
}


.bg-KP {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -370px;
}


.bg-KR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -370px;
}


.bg-KW {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -370px;
}


.bg-KZ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -370px;
}


.bg-LA {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -370px;
}


.bg-LB {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -370px;
}


.bg-LC {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -370px;
}


.bg-LI {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -10px;
}


.bg-LK {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -46px;
}


.bg-LR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -82px;
}


.bg-LS {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -118px;
}


.bg-LT {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -154px;
}


.bg-LU {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -190px;
}


.bg-LV {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -226px;
}


.bg-LY {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -262px;
}


.bg-MA {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -298px;
}


.bg-MC {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -334px;
}


.bg-MD {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -370px;
}


.bg-ME {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -406px;
}


.bg-MG {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -406px;
}


.bg-MH {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -406px;
}


.bg-MK {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -406px;
}


.bg-ML {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -406px;
}


.bg-MM {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -406px;
}


.bg-MN {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -406px;
}


.bg-MR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -406px;
}


.bg-MT {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -406px;
}


.bg-MU {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -406px;
}


.bg-MV {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -10px;
}


.bg-MW {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -46px;
}


.bg-MX {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -82px;
}


.bg-MY {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -118px;
}


.bg-MZ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -154px;
}


.bg-NA {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -190px;
}


.bg-NE {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -226px;
}


.bg-NG {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -262px;
}


.bg-NI {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -298px;
}


.bg-NL {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -334px;
}


.bg-NO {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -370px;
}


.bg-NP {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -406px;
}


.bg-NR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -442px;
}


.bg-NZ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -442px;
}


.bg-OM {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -442px;
}


.bg-PA {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -442px;
}


.bg-PE {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -442px;
}


.bg-PG {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -442px;
}


.bg-PH {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -442px;
}


.bg-PK {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -442px;
}


.bg-PL {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -442px;
}


.bg-PT {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -442px;
}


.bg-PW {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -442px;
}


.bg-PY {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -10px;
}


.bg-QA {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -46px;
}


.bg-RO {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -82px;
}


.bg-RS {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -118px;
}


.bg-RU {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -154px;
}


.bg-RW {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -190px;
}


.bg-SA {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -226px;
}


.bg-SB {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -262px;
}


.bg-SC {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -298px;
}


.bg-SD {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -334px;
}


.bg-SE {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -370px;
}


.bg-SG {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -406px;
}


.bg-SI {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -442px;
}


.bg-SK {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -478px;
}


.bg-SL {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -478px;
}


.bg-SM {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -478px;
}


.bg-SN {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -478px;
}


.bg-SO {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -478px;
}


.bg-SR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -478px;
}


.bg-SS {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -478px;
}


.bg-ST {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -478px;
}


.bg-SV {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -478px;
}


.bg-SY {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -478px;
}


.bg-SZ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -478px;
}


.bg-TD {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -478px;
}


.bg-TG {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -10px;
}


.bg-TH {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -46px;
}


.bg-TJ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -82px;
}


.bg-TL {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -118px;
}


.bg-TM {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -154px;
}


.bg-TN {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -190px;
}


.bg-TO {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -226px;
}


.bg-TR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -262px;
}


.bg-TT {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -298px;
}


.bg-TV {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -334px;
}


.bg-TW {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -370px;
}


.bg-TZ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -406px;
}


.bg-UA {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -442px;
}


.bg-UG {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -478px;
}


.bg-UK {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -10px -514px;
}


.bg-US {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -54px -514px;
}


.bg-UY {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -98px -514px;
}


.bg-UZ {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -142px -514px;
}


.bg-VA {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -186px -514px;
}


.bg-VC {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -230px -514px;
}


.bg-VE {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -274px -514px;
}


.bg-VN {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -318px -514px;
}


.bg-VU {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -362px -514px;
}


.bg-WS {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -406px -514px;
}


.bg-XK {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -450px -514px;
}


.bg-YE {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -494px -514px;
}


.bg-ZA {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -538px -514px;
}


.bg-ZM {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -582px -10px;
}


.bg-ZW {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -582px -46px;
}


.bg-ENG {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -582px -82px;
}


.bg-SCT {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -582px -118px;
}


.bg-WLS {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -582px -154px;
}

.bg-NIR {
  width: 24px;
  height: 16px;
  background: url(../../../Images/Flags.png) -582px -190px;
}
