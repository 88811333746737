.Focus {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  transition: background-color linear 0.25s;

  &.Closing {
    background-color: rgba(0, 0, 0, 0);
  }
}

.Popover {
  position: fixed;
  z-index: 101;

  bottom: 0;
  transform: translateY(100%);

  //bottom: -120px;
  //height: 120px;

  left: 0;
  width: 100%;
  background-color: var(--card-bg);
  border-radius: 10px 10px 0 0;
  border: var(--menu-border);
  box-shadow: 0 0 4px 1px var(--card-shadow);
  box-sizing: border-box;
  padding: 10px 25px 25px 25px;

  animation: appear 0.25s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes appear {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }

  &.Closing {
    animation: disappear 0.25s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes disappear {
      from {
        transform: translateY(0%);
      }
      to {
        transform: translateY(100%);
      }
    }
  }
}

.Handle {
  margin: 0 auto 10px auto;
  width: 55px;
  height: 4px;
  border-radius: 2px;
  background-color: var(--divider);
}
