
.Container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  flex-direction: row;
}

.Bell {
  margin-right: 10px;
  margin-top: 3px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  .IconPath {
    transition: fill 200ms ease;
    fill: var(--user-navbar-icon-fill);
  }

  &:hover .IconPath {
    fill: var(--user-navbar-icon-fill-hover);
  }

  &.Open .IconPath {
    fill: var(--user-navbar-icon-fill-hover);
  }
}

.Chip {
  background-color: var(--danger-red-light-1);
  color: white;
  padding: 1px 5px;
  border-radius: 10px;
  font-size: 10px;
  height: 13px;
  top: 3px;
  user-select: none;

  position: absolute;
  right: 5px;
  font-weight: var(--bold-weight);
}
