

.Card {
  width: 450px;
  margin: 30px auto;
  padding: 30px;
  box-sizing: border-box;

  // Fill width on smaller devices.
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.Form {
  margin-top: 40px;
}

.Title {
  font-size: 24px;
  font-weight: var(--bold-weight);
  text-align: left;

  margin-bottom: 20px;
}

.Label {
  margin-bottom: 10px;
  display: block;
}

.SubmitRegion {
  display: flex;
  justify-content: flex-end;
}

.Error {
  color: var(--danger-red);
}
