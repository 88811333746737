.Section {
  @media only screen and (max-width: 768px) {
    display: block;
  }
  @media only screen and (min-width: 769px) {
    display: flex;
  }

  &:not(:last-of-type) {
    margin-bottom: 50px;
  }
}

.SectionName {
  min-width: 150px;
  color: var(--button-regular-text);
  font-weight: var(--bold-weight);
  font-size: 18px;
  padding-bottom: 20px;
  margin-top: 5px;
}

.Table {
  width: 100%;
  flex-grow: 1;
}

.Row {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    padding-top: 0;
  }

  &:not(:last-of-type) {
    border-bottom: solid 2px var(--divider);
  }
}
