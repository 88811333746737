

.Container {
  max-width: 900px;

  @media only screen and (min-width: 1001px) {
    margin: 90px auto;
    display: flex;
  }
  @media only screen and (max-width: 1000px) {
    margin: 50px auto;
  }
}

.Column {
  &:first-of-type {
    @media only screen and (min-width: 1001px) {
      width: 50%;
      padding-right: 100px;
      box-sizing: border-box;
    }
    @media only screen and (max-width: 1000px) {
      max-width: 500px;
      width: 100%;
      margin: 0 auto 50px;
      padding: 0 30px;
      box-sizing: border-box;
    }
  }

  &:last-of-type {
    @media only screen and (min-width: 1001px) {
      flex-grow: 1;
    }
    @media only screen and (max-width: 1000px) {
      max-width: 500px;
      width: 100%;
      padding: 0 30px;
      margin: 0 auto 50px;
      box-sizing: border-box;
    }
  }
}

.Title {
  margin-top: 10px;
  font-size: 40px;
  font-family: var(--title-font-family);
}

.Subtitle {
  color: var(--light-text);
  margin-top: 30px;
  font-size: 20px;
}

.LinksSection {
  margin-top: 35px;
}

.LinksTitle {
  color: var(--light-text);
  font-size: 16px;
}

.Email {
  font-size: 22px;
  display: inline-block;
  color: var(--default-text);
  font-weight: var(--bold-weight);
  text-decoration: none;
  margin-top: 5px;
}

.Icons {
  margin-top: 15px;
  display: flex;
  filter: var(--icon-brightness);

  .Twitter {
    font-size: 0;
    background: url(./Twitter.svg) no-repeat;
    background-size: auto 25px;
    width: 20px;
    height: 15px;
    padding: 5px;
  }

  .Instagram {
    font-size: 0;
    background: url(./Instagram.svg) no-repeat;
    background-size: auto 25px;
    width: 15px;
    height: 15px;
    margin: 0 20px;
    padding: 5px;
  }

  .Discord {
    font-size: 0;
    background: url(./Discord.svg) no-repeat;
    background-size: auto 25px;
    width: 15px;
    height: 15px;
    padding: 5px;
  }
}

.Form {
  box-sizing: border-box;
  padding: 30px 30px;
}

.Label {
  display: block;
  margin-bottom: 10px;
}

.MessageInput {
  resize: vertical;
  min-height: 100px;
}

.CompleteMessage {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0;

  .ThankYou {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 24px;
  }

  .Message {
    margin-top: 20px;
    font-size: 16px;
    color: var(--light-text);
  }

  .SuccessIcon {
    width: 25px;
    height: 25px;
    margin-left: 10px;
  }
}
