:global {
  .IconSvg {
    height: 18px;
    margin-right: 10px;
  }
}

.Parent {
  position: relative;
}

.AddFriendButton {
  font-weight: var(--bold-weight);
}

.AcceptFriendButton {
  font-weight: var(--bold-weight);
}

.BlockedButton {
  font-weight: var(--bold-weight);
  display: inline-flex;
  color: var(--danger-red-light-1);
  padding: 5px 0;
}

.RequestSent {
  color: var(--light-text);
  display: inline-flex;
  margin: 5px 0;
  font-weight: var(--bold-weight);

  :global {
    .IconSvg {
      fill: var(--light-text);
    }
  }
}

.Friend {
  color: var(--accent-pink);
  display: inline-flex;
  margin: 5px 0;
  font-weight: var(--bold-weight);

  :global {
    .IconSvg {
      fill: var(--accent-pink);
    }
  }
}

.WithMenu {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    .MenuIcon {
      margin-left: 20px;
    }
  }
  @media only screen and (min-width: 769px) {
    justify-content: space-between;
  }
}

.MenuIcon {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-left: 5px;
  margin-left: 10px;

  &:hover :global .IconPath {
    fill: var(--default-text);
  }

  &.Open :global .IconPath {
    fill: var(--default-text);
  }
}

.MenuFloat {
  position: absolute;
  min-width: 125px;
  overflow: hidden;
  margin-top: 10px;
  z-index: 1;
  border: var(--menu-border);

  @media only screen and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    left: calc(100% - 15px);
  }
}

.MenuItem {
  display: block;
  width: 100%;
  color: var(--default-text);
  text-decoration: none;
  padding: 8px 15px;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 200ms ease;

  &:hover {
    background-color: var(--menu-hover);
  }

  svg {
    margin-right: 15px;
    &.UnfriendIcon {
      margin-right: 12px;
    }
  }
}
