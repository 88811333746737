.ListCard {
  padding: 30px 50px 40px 50px;
  display: flex;
  align-items: center;
}

.Content {
  display: block;
  width: 100%;

  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }
}

.SubTitle {
  width: 100%;
  font-size: 20px;
  border-bottom: solid 2px var(--divider);
  padding-bottom: 7px;
}

.TitleBar {
  margin-bottom: 20px;
  height: 52px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    display: none;
  }

  .Title {
    color: var(--default-text);
    font-weight: var(--bold-weight);
    font-size: 22px;
    margin-left: 50px;
  }
}

.Grid {
  padding: 20px 0 30px 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(365px, 1fr));
  grid-gap: 15px 30px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: initial;
  }

  .User {
    background-color: var(--user-list-item);
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;

    @media only screen and (min-width: 769px) {
      max-width: 365px;
    }

    .UserInfo {
      flex-grow: 1;
    }

    .UserActions {
      font-size: 12px;
      margin-right: 10px;
    }

    .ActionDisabled {
      color: #CDCDCD;
      margin-right: 10px;
    }

    .ActionSuccess {
      color: var(--wavy-blue);
      margin-right: 10px;
    }

    .ActionButtons {
      display: flex;
      align-items: center;
    }

    .AcceptButton {
      margin-right: 10px;
    }
  }
}

.NoRequests {
  color: var(--light-text);
  padding: 20px 0 50px;
}

.Skeleton {
  height: 90px;
}
