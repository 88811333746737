
.Alert {
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  border-left: solid 7px #FFC738;
  margin-bottom: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  font-size: 15px;

  a {
    color: var(--default-text);
    font-weight: var(--bold-weight);
  }

  &.Critical {
    border-left: solid 7px var(--danger-red-light-1);
  }
}

.Dismiss {
  height: 15px;
  cursor: pointer;
}
