.TrendingSongsSection {
  display: flex;
  padding: 50px 0;

  .ListParent {
    flex-basis: 100%;
    max-width: 100%;

    .TrendingSongsList {
      padding: 0 5px;

      @media only screen and (min-width: 769px) {
        padding: 8px 5px;
        font-size: 25px;
        margin-left: 40px;
      }
    }
  }
}

.PrimaryColumn {
  @media only screen and (min-width: 769px) {
    min-width: 30vw;
  }
}

.MetricColumn {
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media only screen and (min-width: 769px) {
    font-size: 25px;
  }

  color: var(--accent-pink);
  margin-right: 10px;
}

.MetricIcon {
  @media only screen and (max-width: 768px) {
    margin-left: 8px;
    height: 15px;
  }
  @media only screen and (min-width: 769px) {
    margin-left: 10px;
    height: 22px;
  }
}

.Row {
  @media only screen and (min-width: 769px) {
    padding: 8px 0;
    font-size: 25px;
  }
}

.IconColumn {
  @media only screen and (min-width: 769px) {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background-size: 40px 40px;
    margin-right: 30px;
  }
}

.RankColumn {
  @media only screen and (min-width: 769px) {
    margin-left: 10px;
    min-width: 30px;
  }
}
