

.SectionGetStarted {
  @media only screen and (min-width: 1001px) {
    padding: 100px 0 50px 0;
  }

  @media only screen and (max-width: 1000px) {
    padding: 50px 10px 20px 10px;
  }
}

.Columns {
  width: 100%;
  @media only screen and (min-width: 1001px) {
    display: flex;
  }
  @media only screen and (max-width: 1000px) {
    display: block;
  }

  > * {
    @media only screen and (min-width: 1001px) {
      width: 33.33%;
    }
    @media only screen and (max-width: 1000px) {
      width: 100%;
    }
  }
}

.StepParent {
  width: 100%;
  display: flex;

  @media only screen and (min-width: 1001px) {
    &:nth-of-type(2) {
      justify-content: center;
    }

    &:nth-of-type(3) {
      justify-content: flex-end;
    }
  }
  @media only screen and (max-width: 1000px) {
  }
}

.Step {
  @media only screen and (min-width: 1001px) {
    width: 200px;
  }
  @media only screen and (max-width: 1000px) {
    width: 100%;
    margin-bottom: 50px;
  }
  position: relative;
}

.StepNumber {
  font-size: 20px;
  font-weight: var(--bold-weight);
  letter-spacing: 3px;
  z-index: 5;
  position: relative;
}

.StepTitle {
  margin-top: 5px;
  font-size: 28px;
  font-weight: var(--bold-weight);
  z-index: 5;
  position: relative;
}

.StepDetails {
  font-size: 18px;
  line-height: 28px;
  z-index: 5;
  position: relative;

  @media only screen and (min-width: 1001px) {
    margin-top: 20px;
  }
  @media only screen and (max-width: 1000px) {
    margin-top: 15px;
  }
}

.BigNumber {
  position: absolute;
  font-weight: var(--bold-weight);
  z-index: 0;
  right: 0;
  opacity: 0.5;
  top: -50px;

  @media only screen and (min-width: 1001px) {
    font-size: 150px;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 120px;
  }
}


.FirstColor {
  color: #6FD9D1;
}

.SecondColor {
  color: #A2D9FF;
}

.ThirdColor {
  color: #F9ADC7;
}


@media only screen and (min-width: 1001px) {
  padding: 100px 0;
}

@media only screen and (max-width: 1000px) {
  padding: 20px 10px;
}

.Title {
  font-family: var(--title-font-family);

  @media only screen and (min-width: 1001px) {
    font-size: 35px;
    margin-bottom: 70px;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 30px;
    margin-bottom: 40px;
  }
}

.Container {
  margin: 0 auto;
  width: calc(100% - 20px);
  max-width: 1000px;
}

.JoinButtonParent {
  width: 100%;
  display: flex;
  justify-content: center;
}

.JoinButton {
  margin-top: 80px;

  @media only screen and (max-width: 1000px) {
    width: 100%;
    margin: 30px 0;
    display: block;
    text-align: center;
  }
}
