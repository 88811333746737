

a {
  color: var(--wavy-blue);
  text-decoration: none;
}

.Section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.SectionTitle {
  font-size: 20px;
  font-weight: var(--bold-weight);
  margin-bottom: 10px;
  text-transform: uppercase;
}

.List {
  width: 100%;
}

.Elem {
  padding: 20px 10px;
  border-bottom: solid 2px var(--divider);
  cursor: pointer;
  font-size: 17px;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: var(--about-faq-hover);
  }

  .Body {
    margin-top: 20px;
    font-size: 15px;

    overflow-y: hidden;
    transition: all 0.25s;

    &.Hidden {
      margin-top: 0;
      max-height: 0;
    }
  }
}

.Container {
  margin: 0 auto;
  width: calc(100% - 20px);
  max-width: 900px;
}
