

.Card {
  width: 450px;
  margin: 30px auto;
  padding: 30px;
  box-sizing: border-box;

  // Fill width on smaller devices.
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.Form {
  label {
    margin-left: 5px;
  }
  input[type="radio"] {
    margin-bottom: 8px;
  }
  input[type="submit"] {
    margin-top: 20px;
  }
}

.Title {
  font-size: 24px;
  font-weight: var(--bold-weight);
  text-align: left;

  margin-bottom: 20px;
}

.MessageInput {
  resize: vertical;
  min-height: 100px;
}

.MessageField {
  margin-top: 12px;
}
