.Badge {
  width: var(--badge-size);
  height: var(--badge-size);
  min-width: var(--badge-size);
  min-height: var(--badge-size);
  display: inline-block;
  margin-left: calc(var(--badge-size) * 0.9);
}

.SupporterBadge {
  background-image: url(../../../Images/SupporterBadge.svg);
  background-repeat: no-repeat;
  background-size: var(--badge-size) var(--badge-size);
}

.AdminBadge {
  background-image: url(../../../Images/AdminBadge.svg);
  background-repeat: no-repeat;
  background-size: var(--badge-size) var(--badge-size);
}
