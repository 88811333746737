

.Preface {
  margin: 50px 0 30px 0;
  padding-bottom: 20px;
  border-bottom: solid #DDDDDD 1px;
}

.Container {
  margin: 0 auto;
  width: calc(100% - 20px);
  max-width: 900px;
}

.Content {
  border: solid var(--default-text) 1px;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 3px;
}
