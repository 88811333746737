.Tutorial {
  @media only screen and (min-width: 769px) {
    margin-top: 50px;
    font-size: 20px;
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 50px;
    font-size: 18px;
    text-align: center;
    padding: 0 10px;
    box-sizing: border-box;
  }
}

.SpotifyLinked {

}

.SpotifyNotLinked {

}

.Title {
  font-weight: var(--bold-weight);
  margin-bottom: 10px;
}

.Description {
  color: var(--light-text);
}

.SpotifyButton {
  margin-top: 20px;

  @media only screen and (min-width: 769px) {
    display: inline-block;
  }
  @media only screen and (max-width: 768px) {
  }
}
