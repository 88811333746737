

.ListCard {
  padding: 50px 50px;
  display: flex;
  align-items: center;
}

.Content {
  display: block;
  width: 100%;

  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }
}

.TitleBar {
  margin-bottom: 20px;
  height: 52px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    display: none;
  }

  .Title {
    color: var(--default-text);
    font-weight: var(--bold-weight);
    font-size: 22px;
    margin-left: 50px;
  }
}

.Grid {
  padding-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 240px);
  grid-gap: 15px 10px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: initial;
  }

  .User {
    background-color: var(--user-list-item);
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: 100%;

    .UserInfo {
      flex-grow: 1;
    }

    .AddButton {
      width: 20px;
      height: 16px;
      margin-right: 10px;
      background: url(./AddFriendButton.svg) no-repeat;
      background-size: 20px 16px;
      cursor: pointer;
    }

    .Requested {
      width: 20px;
      height: 16px;
      margin-right: 10px;
      background: url(./Requested.svg) no-repeat;
      background-size: 20px 16px;
    }
  }
}

.SearchBoxParent {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.Placeholder {
  width: 100%;
  max-width: 400px;
  margin: 40px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.PlaceholderText {
  margin-top: 25px;
  color: var(--light-text);
  font-size: 16px;
}

.PlaceholderIcon {
  height: 50px;
  width: 50px;
}

.Results {
  min-height: 135px;
}

.NoResults {
  padding-top: 50px;
  color: var(--light-text);
  font-size: 16px;
  width: 100%;
  text-align: center;
}

