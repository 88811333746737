

.Editor {
  font-family: inherit;
  font-size: 16px;
  max-width: 100%;
  height: 20px;
  background: none;
  border: none;
  width: 100%;
  margin: 16px 0;
  resize: none;
  outline: none !important;
  overflow: hidden;
  box-sizing: border-box;
  color: var(--default-text);
}

.Highlighted {

}

.ReadOnlyContainer {
  overflow: hidden;

  p {
    margin: 5px 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

.EditorContainer {
  background-color: var(--comment-input-bg);
  padding: 5px 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  cursor: text;

  &.Focused {
    background-color: var(--comment-input-focused-bg);

    .Editor::placeholder {
      color: var(--comment-input-focused-placeholder);
    }
  }
}

.SendButton {
  float: right;
  padding: 5px 10px !important;
  margin-bottom: 5px;
  transition: background-color 200ms;
  user-select: none;

  &:hover {
    background-color: var(--comment-input-bg);
  }
}

.BlockedComment {
  color: var(--light-text);
  font-style: italic;
  cursor: pointer;
}
