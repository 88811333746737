.WarningText {
  max-width: 350px;
}

.DisconnectWarning {
  color: var(--danger-red);
}

.ActionButtons {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  * {
    margin-left: 20px;
  }
}

.DiscordLink {
  color: var(--discord-purple);
}

.DescriptionParent {
  min-width: 0;
  flex-grow: 1;
}
