

.SummaryColumn {
  width: 240px;
  padding: 0 10px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }

  .UserInfo {
    display: flex;
    align-items: center;

    .Username {
      font-size: 20px;
      font-weight: var(--bold-weight);
      margin-left: 20px;
      color: var(--default-text);
    }
  }
}

.SocialPage {
  display: flex;
  width: 100%;
  padding-top: 40px;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    display: block;
    padding-top: 20px;
  }
}

.Navigation {
  margin-left: 26px;
  margin-bottom: 30px;
  height: 52px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }

  a {
    display: inline-block;
    padding: 0;
    margin-right: 40px;
    border-bottom: solid transparent 2px;
    color: var(--default-text);
    font-size: 20px;

    &.Selected, &:hover {
      border-bottom-color: var(--wavy-blue);
    }

    &.Selected {
      font-weight: var(--bold-weight);
    }
  }
}

.ListColumn {
  flex-grow: 1;
  max-width: 860px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
