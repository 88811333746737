


.AuthError {
  background-color: #CC4125;
  padding: 15px 10px;
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 500px;
  box-sizing: border-box;
  color: white;
  border-radius: 3px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.AuthCard {
  width: 500px;
  margin: 30px auto;

  // Fill width on smaller devices.
  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  .LogoParent {
    width: 100%;
    margin: 15px 0;
    display: inline-block;

    .Logo {
      display: block;
      height: 50px;
      width: 66px;
      background: var(--logo-default) no-repeat;
      background-size: auto 100%;
      margin-left: auto !important;
      margin-right: auto !important;
      margin-top: 10px;
    }
  }

  .AuthTitle {
    width: 100%;
    text-align: center;
    font-size: 25px;
    margin-bottom: 20px;
    display: inline-block;

    a {
      color: #000000;
      font-weight: var(--bold-weight);
    }

    .Or,
    .Inactive {
      color: var(--light-text);
    }

    .Inactive:hover {
      border-bottom: solid var(--wavy-blue) 2px;
    }

    .Active {
      color: var(--default-text);
      border-bottom: solid var(--wavy-blue) 2px;
    }
  }

  .Divider {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    border-top: solid var(--divider) 1px;
    padding-bottom: 20px;
  }

  .AuthForm {
    width: 75%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    overflow: hidden;
    transition: height 0.4s ease;

    &.View-join {
      height: 470px;
    }

    &.View-login {
      height: 250px;
    }
  }
}

.AuthFormParent {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  .LoginFormParent, .JoinFormParent {
    transition: left ease 0.4s;
    left: 0;
    position: absolute;
    width: calc(100% - 20px);
    margin-left: 10px;
  }

  .LoginFormParent {
    margin-left: 10px;
  }

  &.Right .JoinFormParent {
    left: calc(-100% - 20px);
  }

  &.Left .LoginFormParent {
    left: 100%;
  }

  label {
    margin-bottom: 10px;
    display: block;
  }

  .TermsNotice {
    color: var(--light-text);
    font-size: 14px;

    a {
      color: var(--default-text);
    }
  }

  .SubmitRegion {
    display: flex;

    .ForgotPassword {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      text-align: center;
      width: 100%;

      a {
        color: var(--light-text);
      }
    }

    .FormSubmit {
      font-size: 16px;
      cursor: pointer;

      &[disabled] {
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}
