.StatsCard {
}

.Stats {
  display: flex;
  width: 100%;
}

.Default {
  margin-top: 30px;
  margin-left: -30px;
  margin-right: 20px;
  padding: 18px 34px;

  .Stats {
    justify-content: space-between;
  }
}

.Content {
  margin-top: 20px;
}

.Mobile {
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 5px;

  .Stat {
    margin-right: 40px;
  }
}

.Stat {
  .StatValue {
    font-size: 22px;
    font-weight: var(--bold-weight);
  }

  .StatName {
    color: var(--light-text);
    font-weight: normal;
  }
}
