//$wavy-blue-light-2: rgb(104, 210, 224);
//$wavy-blue-light-1: rgb(87, 202, 218);
//$wavy-blue: #49C5D3;
//$wavy-blue-dark-1: #27909a;
//$wavy-blue-dark-2: #1e757f;
//
//$danger-red-light-2: #ffa7ae;
//$danger-red-light-1: #ff5464;
//$danger-red: #ff253e;
//
//$spotify-green-light-2: #74d180;
//$spotify-green-light-1: #20d15d;
//$spotify-green: #1DB954;
//
//$discord-purple-light-2: #8298e7;
//$discord-purple-light-1: #7890e5;
//$discord-purple: #7289da;
//
//$gray: #DADCE0;
//$shadow: 0 0 5px #c9cbcf;
//
//$default-text: #212121;
//$background: #FAFAFA;
//$light-text: #8a9ca9;

html {
  --font-family: 'Outfit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';

  --title-font-family: 'Gilroy-Bold-wavy', 'Outfit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';

  --shadow: 0 0 5px #c9cbcf;

  --wavy-blue-light-2: rgb(104, 210, 224);
  --wavy-blue-light-1: rgb(87, 202, 218);
  --wavy-blue: #49C5D3;
  --wavy-blue-dark-1: #27909a;
  --wavy-blue-dark-2: #1e757f;

  --accent-pink-transparent: rgba(245, 92, 149, 0);
  --accent-pink-light-3: rgba(245, 92, 149, 0.65);
  --accent-pink-light-2: rgba(245, 92, 149, 0.74);
  --accent-pink-light-1: rgba(245, 92, 149, 0.87);
  --accent-pink: #EE5B8F;

  --danger-red-light-2: #ffa7ae;
  --danger-red-light-1: #ff5464;
  --danger-red: #ff4e4e;

  --spotify-green-light-2: #74d180;
  --spotify-green-light-1: #20d15d;
  --spotify-green: #1DB954;

  --discord-purple-light-2: #8298e7;
  --discord-purple-light-1: #7890e5;
  --discord-purple: #7289da;

  --button-light-text: var(--light-text);
  --button-light-hover-bg: #ebedf1;
  --button-light-active-text: var(--light-text);
  --button-light-active-bg: #e1e3e7;

  --button-regular-text: var(--default-text);
  --button-regular-outline-text: var(--default-text);
  --button-regular-bg: #EFEFEF;
  --button-regular-hover-bg: #e1e3e7;
  --button-regular-active-bg: #e4e6e8;
  --button-regular-active-text: #343434;
  --button-regular-outline-border: #E7E9EB;
  --button-regular-outline-hover-bg: var(--button-regular-hover-bg);
  --button-regular-outline-active-bg: var(--button-regular-active-bg);

  --gray: #DADCE0;
  --menu-hover: #f2f2f2;
  --menu-border: none;

  --card-bg: #FEFEFE;
  --card-shadow: rgba(204, 204, 204, 0.5);
  --modal-shadow: rgba(0, 0, 0, 0.2);
  --card-border: var(--gray);

  --search-box-bg: #EFEFEF;
  --search-box-bg-focus: #F6F6F6;
  --search-box-icon-button: url(./Images/SearchIcon.svg);
  --search-box-icon-placeholder: url(./Images/SearchIconGray.svg);
  --search-bar-result-bg: var(--background);
  --search-bar-result-shadow: #252526;
  --search-bar-result-selected: var(--gray);
  --search-bar-mobile-text: #9E9E9E;
  --search-bar-mobile-bg: #F5F5F5;

  --avatar-shadow: 0 1px 3px var(--card-shadow);
  --avatar-background: #FFFFFF;

  --profile-flag-shadow: 0 0 3px 0 #CCC;
  --profile-edit-bg: #F3F3F3;
  --profile-comment-disabled: #F5F5F5;
  --profile-comment-highlight: #f0f0f0;
  --profile-grid-cell: #F6F6F6;

  --calendar-day-no-listens-bg: #E7E7E7;
  --calendar-day-no-listens-text: #B4B4B4;

  --user-list-item: #F9F9F9;

  --notification-delete-hover: #F3F3F3;
  --pagination-hover: #F3F3F3;

  --default-text-orig: #212121;
  --default-text: #212121;
  --default-text-dark: #FAFAFA;
  --default-text-inverted: #FAFAFA;
  --background: #FAFAFA;
  --background-dark: #212121;
  --background-inverted: #212121;
  --background-alt: #F5F5F5;
  --light-text: #8a9ca9;

  --logo-default: url(./Images/wavy-logo.svg);
  --logo-white: url(./Images/wavy-logo-white.svg);

  --about-timeline-current-bg: var(--background);
  --about-timeline-current-text: var(--default-text);
  --about-timeline-future-bg: #303030;
  --about-timeline-future-text: #FAFAFA;
  --about-header-bg: var(--background);
  --about-faq-hover: #EFEFEF;
  --icon-brightness: none;

  --dark-mode-toggle: url(./Images/LightToggle.svg);
  --home-profile-link-icon: url(./Images/ListenLink.svg);
  --home-profile-link-icon-bg: #FFC1D7;

  --divider: #F2F2F2;

  --comment-divider: #F2F2F2;
  --comment-box-bg: #F2F2F2;
  --comment-input-bg: #EFEFEF;
  --comment-input-focused-bg: #F6F6F6;
  --comment-input-focused-placeholder: #808080;

  --user-navbar-bg: #EFEFEF;
  --user-navbar-bg-hover: #e4e6e8;
  --user-navbar-menu-border: none;
  --user-navbar-icon-fill: #687A8D;
  --user-navbar-icon-fill-hover: #5F7081;

  --input-background: #F8F8F8;
  --input-border: #CCCCCC;
  --input-disabled: #CCCCCC;

  --bold-weight: 500;
}

html[data-theme="dark"] {
  --default-text: #ECE9E4;
  --default-text-dark: #FAFAFA;
  --default-text-inverted: #212121;
  --background: #111111;
  --background-dark: #181818;
  --background-inverted: #FAFAFA;
  --background-alt: #111111;
  --light-text: #7D7D7D;

  --gray: #212121;
  --menu-hover: #1d1d1d;
  --menu-border: solid 2px var(--divider);

  --avatar-shadow: 0 1px 3px var(--card-shadow);
  --avatar-background: var(--background);

  --profile-flag-shadow: 0 0 3px 0 var(--background-dark);
  --profile-edit-bg: var(--card-bg);
  --profile-comment-disabled: var(--card-bg);
  --profile-comment-highlight: #262626;
  --profile-grid-cell: var(--card-bg);

  --calendar-day-no-listens-bg: #222223;
  --calendar-day-no-listens-text: #AEAEAE;

  --user-list-item: #262626;

  --notification-delete-hover: #2b2b2b;
  --pagination-hover: #2b2b2b;

  --card-bg: var(--background-dark);
  --card-shadow: transparent;
  --modal-shadow: transparent;
  --card-border: var(--background-dark);

  --search-box-bg: #222223;
  --search-box-bg-focus: #353536;
  --search-bar-result-bg: #252526;
  --search-bar-result-shadow: #252526;
  --search-bar-result-selected: #353536;
  --search-bar-result-icon-border: var(--background);
  --search-bar-mobile-text: var(--light-text);
  --search-bar-mobile-bg: var(--card-bg);

  --accent-pink-transparent: rgba(240, 75, 106, 0);
  --accent-pink-light-3: rgba(240, 75, 106, 0.65);
  --accent-pink-light-2: rgba(240, 75, 106, 0.74);
  --accent-pink-light-1: rgba(240, 75, 106, 0.87);
  --accent-pink: rgba(240, 75, 106, 1);

  --button-light-text: var(--default-text);
  --button-light-hover-bg: #202020;
  --button-light-active-text: var(--default-text);
  --button-light-active-bg: #222223;

  --button-regular-text: #666666;
  --button-regular-outline-text: #ECE9E4;
  --button-regular-bg: #EFEFEF;
  --button-regular-hover-bg: #e1e3e7;
  --button-regular-active-bg: #d0d2d6;
  --button-regular-active-text: #222223;
  --button-regular-outline-border: #222223;
  --button-regular-outline-hover-bg: var(--background-dark);
  --button-regular-outline-active-bg: #333333;

  --logo-default: url(./Images/wavy-logo-inverted.svg);
  --logo-white: url(./Images/wavy-logo-white.svg);

  --about-timeline-current-bg: #ECE9E4;
  --about-timeline-current-text: #212121;
  --about-timeline-future-bg: var(--background);
  --about-timeline-future-text: var(--default-text);
  --about-header-bg: var(--background-dark);
  --about-faq-hover: var(--background-dark);
  --icon-brightness: brightness(7.5);

  --dark-mode-toggle: url(./Images/DarkToggle.svg);
  --home-profile-link-icon: url(./Images/ListenLinkDark.svg);
  --home-profile-link-icon-bg: #F04B6A;

  --divider: #222223;
  --comment-divider: #222223;

  --user-navbar-bg: #222223;
  --user-navbar-bg-hover: #333333;
  --user-navbar-icon-fill: #7D7D7D;
  --user-navbar-icon-fill-hover: #8F8F8F;

  --user-navbar-menu-border: solid 2px var(--divider);
  --comment-input-bg: var(--background-dark);
  --comment-input-focused-bg: var(--input-background);
  --comment-input-focused-placeholder: #808080;

  --input-background: #222223;
  --input-border: #111111;
  --input-disabled: #CCCCCC;

  --bold-weight: 500;
}
