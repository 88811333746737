.Background {
  width: 100%;
  z-index: 1;
  position: relative;
  box-sizing: border-box;

  // Swapped colors
  background-color: var(--background-dark);
  color: var(--default-text-dark);
}

.Container {
  margin: 0 auto;
  width: calc(100% - 50px);
  max-width: 1296px;
}

.TimelineGroup {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  position: relative;

  @media only screen and (max-width: 768px) {
    justify-content: normal;
  }
}

.TimelineGuide {
  width: 7px;
  flex-shrink: 0;
}

.Beyond {
  width: 0;
  border-left: 7px dashed var(--about-timeline-future-bg);
  height: 125px;
}

.Current {
  .TimelineGuide, .EventDate, .EventGuide {
    background-color: var(--about-timeline-current-bg);
    color: var(--about-timeline-current-text);
  }

  .TimelineSide:nth-of-type(1) {
    margin-top: 65px;
  }

  .TimelineSide:nth-of-type(2) {
    margin-top: 220px;
    .EventContainer {
      text-align: left;
      flex-direction: row-reverse;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 100px;
    }
  }
}

.Future {
  .TimelineGuide, .EventDate, .EventGuide {
    background-color: var(--about-timeline-future-bg);
    color: var(--about-timeline-future-text);
  }

  .TimelineSide:nth-of-type(1) {
    margin-top: 200px;
  }

  .TimelineSide:nth-of-type(2) {
    margin-top: 30px;
    .EventContainer {
      text-align: left;
      flex-direction: row-reverse;
    }

    @media only screen and (max-width: 768px) {
      margin-top: 50px
    }
  }
}

.YouAreHere {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: #71D9E3;
  border: solid 4px var(--background-dark);

  position: absolute;
  top: -2px;
  z-index: 2;

  animation-name: pulse;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;

  $max-pulse-radius: 20px;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 var(--wavy-blue);
    }

    70% {
      box-shadow: 0 0 0 $max-pulse-radius rgba(238, 91, 143, 0);
    }

    100% {
      box-shadow: 0 0 0 $max-pulse-radius rgba(238, 91, 143, 0);
    }
  }

  @media only screen and (max-width: 768px) {
    transform: translateX(-14px);
  }
}

.EventContainer {
  width: 420px;
  display: flex;
  align-items: flex-start;
  text-align: right;

  @media only screen and (max-width: 768px) {
    width: 300px;
  }

  &:last-of-type {
    margin-bottom: 50px;

    @media only screen and (max-width: 768px) {
      margin-bottom: 80px;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 120px;

    @media only screen and (max-width: 768px) {
      margin-bottom: 80px;
    }
  }

  .EventMeta {
    width: 340px;

    @media only screen and (max-width: 768px) {
      width: 300px;
    }
  }

  .EventDate {
    display: inline-block;
    font-size: 36px;
    font-family: var(--title-font-family);
    padding: 20px 30px 15px 30px;

    @media only screen and (max-width: 768px) {
      font-size: 32px;
      padding: 15px 20px 12px 20px;
    }
  }

  .EventTitle {
    font-size: 36px;
    margin: 10px 0;
    font-weight: var(--bold-weight);
  }

  .EventText {
    font-size: 20px;
  }

  .EventGuide {
    height: 7px;
    width: 80px;
    margin-top: 39px;

    @media only screen and (max-width: 768px) {
      width: 50px;
      margin-top: 30px;
    }
  }
}
