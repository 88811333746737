.MusicList {
}

.Row {
  padding: 5px 0;

  width: 100%;
  border-bottom: 1px solid var(--divider);
  display: flex;
  align-items: center;

  transition: background-color ease 200ms;
  background-color: transparent;

  &.AutoCollapse {
    @media only screen and (max-width: 768px) {
      .MainColumn {
        flex-direction: column;
        max-width: 60%;
        flex-basis: 65%;
      }

      .PrimaryColumn {
        max-width: 100%;
      }

      .SecondaryColumn {
        font-size: 13px;
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }

  &.RemoveLastRowBorder:last-of-type {
    border-bottom-color: transparent;
  }

  &:hover, &.Opened {
    @media only screen and (min-width: 769px) {
      background-color: var(--menu-hover);
    }
  }
}

.Columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  max-width: 100%;
}

.IconColumn {
  width: 30px;
  height: 30px;
  min-width: 30px;
  background-size: 30px 30px;
  margin-left: 5px;
  flex-basis: 30px;
  margin-right: 20px;
  background-repeat: no-repeat;
}

.RankColumn {
  margin-left: 5px;
  flex-basis: 15px;
  font-weight: var(--bold-weight);
  margin-right: 5px;
  text-align: center;
}

.MainColumn {
  display: flex;
  flex-basis: 100%;
}

.PrimaryColumn {
  flex-basis: 50%;
  min-width: 0;

  font-weight: var(--bold-weight);
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    max-width: 85%;
  }
}

.SecondaryColumn {
  flex-basis: 40%;
  max-width: 33%;

  display: flex;
  align-items: center;
  position: relative;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
  }
}

.EllipsisColumn {
  cursor: pointer;
  display: none;
  padding: 0.3em 0.75em;
  align-self: flex-end;
  align-items: center;

  &.Opened {
    display: flex;
  }

  .EllipsisIcon {
    margin-right: 0;
    height: 1em;
  }

  &:hover :global .IconPath {
    fill: var(--default-text);
  }

  &.Opened :global .IconPath {
    fill: var(--default-text);
  }
}

@media only screen and (min-width: 769px) {
  .Row:hover:not(.Editing) {
    .EllipsisColumn {
      display: flex;
    }
  }
}

.MetricColumn {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--light-text);
  margin-right: 9px;
  justify-content: flex-end;

  flex-basis: 8em;
  flex-grow: 1;

  span {
    white-space: nowrap
  }
}

.ViewMore {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  .ViewMoreLink {
    display: flex;
    align-items: center;

    // Icon
    :global .IconSvg {
      margin-left: 8px;
      transition: transform 200ms ease;
    }

    &:hover :global .IconSvg {
      transform: translateX(3px);
    }
  }
}

.Deleted {
  *:not(.EditIcon) {
    opacity: 0.75;
  }
}

.EditIcon {
  margin-right: 10px;
  flex-basis: 12px;
  cursor: pointer;
}
