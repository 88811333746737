

.User {
  display: flex;
  align-items: center;
}

.UserInfo {
  margin-left: 10px;
}

.UserName {
  font-size: 18px;
  margin-bottom: 5px;

  a {
    color: var(--default-text);
  }
}

.Activity {
  height: 30px;
  display: flex;
  align-items: center;
}

.Artwork {
  height: 100%;
  min-width: 30px;
  width: 30px;
  background-size: 30px 30px;
  background-repeat: no-repeat;
}

.SongInfo {
  display: flex;
  margin-left: 7px;
  flex-direction: column;
}

.SongName {
  font-size: 13px;
  font-weight: var(--bold-weight);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}

.SongArtist {
  font-size: 11px;
  color: var(--light-text);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}
