.MusicSubject {
  width: 100%;
  max-width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  align-items: center;
}

.Image {
  width: 48px;
  height: 48px;
  margin-right: 18px;

  &.Rounded {
    border-radius: 100%;
  }
}

.Meta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 48px;
  max-width: 75%;

  .Name {
    font-size: 20px;
    font-weight: var(--bold-weight);
    color: var(--default-text);
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .Subtext {
    font-size: 17px;
    font-weight: var(--bold-weight);
    color: var(--light-text);

    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
