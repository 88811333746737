.Button {
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  padding: 8px 17px;
  text-decoration: none;
  border: solid 1px transparent;
}

.Primary {
  background-color: var(--wavy-blue);
  color: white;

  &:hover {
    background-color: var(--wavy-blue-light-1);
  }

  &:active {
    background-color: var(--wavy-blue-light-2);
    box-shadow: 0 0 3px var(--wavy-blue-light-2);
  }

  :global {
    .IconPath {
      stroke: var(--wavy-blue-light-2);
    }
  }

  &.Outline {
    background: none;
    color: var(--wavy-blue);
    border: solid 1px var(--wavy-blue);

    &:hover {
      color: white;
      background-color: var(--wavy-blue-light-1);

      :global {
        .IconPath {
          stroke: white;
        }
      }
    }

    &:active {
      color: white;
      background-color: var(--wavy-blue-light-2);

      :global {
        .IconPath {
          stroke: white;
        }
      }
    }
  }
}

.Accent {
  background-color: var(--accent-pink);
  color: white;

  &:hover {
    background-color: var(--accent-pink-light-1);
  }

  &:active {
    background-color: var(--accent-pink-light-2);
    box-shadow: 0 0 3px var(--accent-pink-light-2);
  }

  :global {
    .IconPath {
      stroke: white;
    }
  }

  &.Outline {
    background: none;
    color: var(--accent-pink);
    padding: 5px 0;

    :global {
      .IconPath {
        stroke: var(--accent-pink);
      }
    }

    &:hover {
      color: var(--accent-pink-light-1);

      :global {
        .IconPath {
          stroke: var(--accent-pink-light-1);
        }
      }
    }

    &:active {
      color: var(--accent-pink-light-2);
      box-shadow: none;

      :global {
        .IconPath {
          stroke: var(--accent-pink-light-2);
        }
      }
    }
  }
}

.Danger {
  color: var(--danger-red);
  border: solid 1px var(--danger-red);
  background-color: transparent;

  &:hover {
    color: white;
    background-color: var(--danger-red-light-1);
  }

  &:active {
    color: white;
    background-color: var(--danger-red-light-2);
    box-shadow: 0 0 3px var(--danger-red-light-2);
  }
}

.Spotify {
  background-color: var(--spotify-green);
  color: white;

  &:hover {
    background-color: var(--spotify-green-light-1);
  }

  &:active {
    background-color: var(--spotify-green-light-2);
    box-shadow: 0 0 3px var(--spotify-green-light-2);
  }
}

.Discord {
  background-color: var(--discord-purple);
  color: white;

  &:hover {
    background-color: var(--discord-purple-light-1);
  }

  &:active {
    background-color: var(--discord-purple-light-2);
    box-shadow: 0 0 3px var(--discord-purple-light-2);
  }
}

.Regular {
  background-color: var(--button-regular-bg);
  color: var(--button-regular-text);

  &:hover {
    background-color: var(--button-regular-hover-bg);
  }

  &:active {
    background-color: var(--button-regular-active-bg);
    color: var(--button-regular-active-text);
  }

  &.Mobile {
    background-color: transparent;
    color: #595959;

    &:hover {
      background-color: #e1e3e7;
    }

    &:active {
      background-color: #d0d2d6;
      color: #343434;
    }
  }

  &.Outline {
    border: solid 1px var(--button-regular-outline-border);
    background-color: transparent;
    color: var(--button-regular-outline-text);

    &:hover {
      background-color: var(--button-regular-outline-hover-bg);
    }

    &:active {
      background-color: var(--button-regular-outline-active-bg);
      color: var(--button-regular-outline-text);
    }
  }
}

.Light {
  background-color: transparent;
  color: var(--button-light-text);

  &:hover {
    background-color: var(--button-light-hover-bg);
  }

  &:active {
    background-color: var(--button-light-active-bg);
    color: var(--button-light-active-text);
  }
}

.DisplayBlock {
  display: block;
}

.Disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.Big {
  font-size: 22px;
  padding: 8px 50px
}

.Medium {
  font-size: 18px;
  padding: 8px 20px
}

.Small {
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 5px;
}
