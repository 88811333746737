.TopSongsSection {
  margin: 50px 0;
  min-height: 270px;
}

.TopSongsTitle {
  font-size: 20px;
  height: 24px;
  font-weight: var(--bold-weight);
  margin: 20px 0 10px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .LeftSide {
    display: flex;
    align-items: center;
  }
}

.TopSongsList {
  @media only screen and (min-width: 769px) {
    max-width: 705px;
  }
}

.Loading {
  opacity: 0.3;
}

.SaveAsDefault {
  cursor: pointer;
  font-size: 13px;
  color: var(--wavy-blue);
  margin-right: 10px;
}
