$meta-width-large: 200px;
$alt-width-large: 230px;

.ProfilePage {
  margin: 15px auto 0 auto;
  width: calc(100% - 40px);
  max-width: 1296px;

  @media only screen and (max-width: 768px) {
    display: block;
  }
  @media only screen and (min-width: 769px) {
    display: flex;
    margin-top: 25px;
  }
}

.Meta {
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  @media only screen and (min-width: 769px) {
    max-width: $meta-width-large;
    min-width: $meta-width-large;
  }
}

.Content {
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 10px 3px;
  }
  @media only screen and (min-width: 769px) {
    flex-grow: 1;
    padding: 0 80px;
  }
}

.Alt {
  max-width: $alt-width-large;
  min-width: $alt-width-large;

  // Hide alt column on medium/small devices
  @media only screen and (max-width: 1000px) {
    display: none;
  }
}
