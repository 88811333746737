

.ProfileMeta {
  width: 100%;
}

.MetaHeader {
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
  }
  @media only screen and (min-width: 769px) {
    display: block;
  }
}

.HeaderText {
  @media only screen and (max-width: 768px) {
    margin-left: 25px;
  }
  @media only screen and (min-width: 769px) {
  }
}

.Avatar {
  @media only screen and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    margin-bottom: 15px;
  }
}

.Username {
  display: flex;
  align-items: baseline;
  @media only screen and (min-width: 769px) {
    margin-bottom: 10px;
  }
}

.UsernameText {
  font-weight: var(--bold-weight);
  color: var(--default-text);

  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 769px) {
    font-size: 26px;

    margin-left: 3px;
    word-wrap: break-word;
    max-width: 200px;
  }
}

.JoinDate {
  font-size: 16px;
  color: var(--light-text);

  @media only screen and (max-width: 768px) {
    margin-top: 5px;
  }
  @media only screen and (min-width: 769px) {
    margin-left: 3px;
  }
}

.SpotifyBadge {
  background-image: url(../../../Images/spotify-icon-green.png);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  display: inline-block;

  margin-left: 15px;
}

.MetaBio {
  @media only screen and (max-width: 768px) {
    margin-top: 15px;
    display: flex;
    width: 100%;
    max-width: 100%;
  }
  @media only screen and (min-width: 769px) {
  }
}

.BioLayoutContent {
  @media only screen and (max-width: 768px) {
    box-sizing: border-box;
    padding-right: 20px;
    flex-grow: 1;
    word-wrap: break-word;
    word-break: break-word;
    max-width: 100%;

    .BioElement {
      margin-left: 3px;
    }
  }
  @media only screen and (min-width: 769px) {
    max-width: 100%;
    word-wrap: break-word;

    .BioElement {
      border-top: solid 1px var(--divider);
      margin-top: 10px;
      padding-top: 10px;
      margin-left: 3px;
    }
  }
}

.BioLayoutActions {
  white-space: nowrap;
  @media only screen and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    margin-top: 15px;
  }
}

.EditButton {
  display: inline-block;
}

.Country {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    color: var(--light-text);
    margin-top: 10px;
    :global {
      .IconSvg {
        fill: var(--light-text);
      }
    }
  }

  @media only screen and (min-width: 769px) {
    :global {
      .IconSvg {
        display: none;
      }
    }
  }

  .Flag {
    margin-left: 8px;
    transform: scale(0.85);
  }
}

.SocialLinks {
  @media only screen and (max-width: 768px) {
    display: none;
  }

  .SocialLink {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    a {
      color: var(--default-text);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .Icon {
      margin-right: 8px;
      min-width: 15px;
    }
  }
}

.BioText {
  @media only screen and (max-width: 768px) {
    p {
      display: inline-block;
      margin: 0 5px 0 0;
    }
  }
  @media only screen and (min-width: 769px) {
    p {
      margin: 5px 0;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}
