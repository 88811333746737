

.HomePage {
  .Columns {
    display: flex;
    width: 100%;

    // Prevent blob collision
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 1000px) {
      display: block;
    }

    .FriendsActivityParent {
      flex-grow: 1;
      margin-top: 50px;
      padding-left: 100px;
      padding-right: 0;
      min-height: 200px;

      @media only screen and (max-width: 1200px) {
        padding-left: 100px;
      }

      @media only screen and (max-width: 600px) {
        margin-top: 50px;
        padding-left: 25px;
      }
    }
  }

  .Blob {
    z-index: 0;

    &.TopBlob {
      position: absolute;
      top: -20px;
      left: calc(((100% - 1296px) / 2) + 100px);

      // Hide on mobile
      @media only screen and (max-width: 600px) {
        display: none;
      }

      @media only screen and (max-width: 1296px) {
        left: 60px;
      }
    }

    &.RightBlob {
      position: absolute;
      top: 100px;
      right: 0;

      // Hide on mobile
      @media only screen and (max-width: 1600px) {
        display: none;
      }
    }

    &.BottomBlob {
      position: absolute;
      left: calc(((100% - 1296px) / 2) + 400px);
      top: 395px;

      @media only screen and (max-width: 1296px) {
        left: 300px;
      }

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }

    &.LeftBlob {
      position: absolute;
      left: 0;
      top: 420px;

      @media only screen and (max-width: 1296px) {
      }

      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }
  }

  .TopArtistsContainer {
    margin-top: 75px;
    width: 100%;
    z-index: 1;
    position: relative;

    // Swapped colors
    background-color: var(--background-dark);
    color: var(--default-text-dark);

    .Title {
      font-size: 45px;
      text-align: right;
      padding-top: 40px;
      margin-right: 40px;
      font-family: var(--title-font-family);

      @media only screen and (max-width: 600px) {
        text-align: center;
        margin-right: 0;
      }
    }
  }

  .TopSongsContainer {
    .Title {
      font-size: 45px;
      text-align: left;
      font-family: var(--title-font-family);
      padding-top: 40px;
      margin-left: 40px;

      @media only screen and (max-width: 600px) {
        text-align: center;
        margin-left: 0;
      }
    }

    // TEMPORARY
    min-height: 400px;
  }

  .MainPlaceHolder {
    height: 400px;
  }

  .AltPlaceHolder {
    height: 300px;
  }
}
