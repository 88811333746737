

.App {
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  .PageContent {
    flex: 1;
    min-height: 450px;
  }

  .GlobalBarLoader {
    position: relative;
    height: 0;
  }
}

.NavBar {
  min-height: 60px;
  font-size: 15px;
  color: var(--default-text);
  border-bottom: 1px solid var(--card-border);
  box-sizing: border-box;
  background-color: var(--card-bg);

  // Make blobs go under
  z-index: 99;
  position: relative;
}

.NavBarContent {
  display: flex;
}

.NavBar .Logo {
  height: 60px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  background-image: var(--logo-default);
  background-repeat: no-repeat;
  background-size: auto 37px;
  background-position-y: 12px;
  width: 200px;

  // Remove wavy.fm from small devices
  @media only screen and (max-width: 600px) {
    width: 50px;
  }
}

.GuestNavBar {
  min-height: 60px;
  font-size: 15px;
  color: var(--default-text);
  box-sizing: border-box;

  .GuestNavContainer {
    margin: 0 auto;
    width: calc(100% - 20px);
    max-width: 1296px;
    display: flex;
    align-items: center;
  }

  .GuestNavLeft {
    display: flex;
    align-items: center;
    margin-right: 30px;
    @media only screen and (max-width: 768px) {
      margin-right: 0;
    }

    .GuestNavLink {
      @media only screen and (max-width: 768px) {
        display: none;
      }
      color: var(--default-text);
      margin-left: 60px;
      font-size: 20px;
      font-weight: var(--bold-weight);
    }
  }

  .GuestLogo {
    height: 60px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;

    background-image: var(--logo-default);
    background-repeat: no-repeat;
    background-size: auto 30px;
    background-position-y: 15px;
    width: 160px;

    @media only screen and (max-width: 768px) {
      width: 40px;
    }
  }
}

:global {
  .Container {
    margin: 0 auto;
    width: calc(100% - 20px);
    max-width: 1296px;
  }

  .NavBar-Item {
    display: flex;
    align-items: center;

    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }

  .FormField {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  input[type=text], input[type=email], input[type=password], textarea, select {
    &:not(.NoDefaultStyle) {
      border: none;
      border-bottom: solid 2px var(--input-border);
      font-family: inherit;

      background-color: var(--input-background);
      font-size: 16px;
      resize: none;
      padding: 5px;
      color: var(--default-text);
      width: 100%;
      box-sizing: border-box;

      &:focus {
        background-color: var(--background);
        border-bottom-color: var(--wavy-blue);
        outline: none;
      }

      &:-moz-ui-invalid, &:-moz-submit-invalid {
        border-bottom-color: var(--danger-red);
        box-shadow: none;
      }

      &:disabled {
        color: var(--input-disabled);
      }
    }
  }

  input[type=submit] {
    font-family: inherit;
    font-size: 16px;
  }


  a, span {
    text-decoration: none;

    &.BasicLink {
      color: var(--wavy-blue);
      cursor: pointer;
    }

    &.AccentLink {
      color: var(--accent-pink);
      cursor: pointer;
    }
  }

}
