

.Calendar {
  width: 350px;
  max-width: 100%;
  background: var(--card-bg);
  line-height: 1.125em;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--default-text);

  abbr {
    font-family: var(--font-family) !important;
  }

  :global {
    .react-calendar--doubleView {
      width: 700px;
    }

    .react-calendar--doubleView .react-calendar__viewContainer {
      display: flex;
      margin: -0.5em;
    }

    .react-calendar--doubleView .react-calendar__viewContainer > * {
      width: 50%;
      margin: 0.5em;
    }

    *,
    *:before,
    *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    button {
      margin: 0;
      border: 0;
      outline: none;
    }

    button:enabled:hover {
      cursor: pointer;
    }

    .react-calendar__navigation {
      height: 44px;
      margin-bottom: 0.5em;
      display: flex;
    }

    .react-calendar__navigation button {
      min-width: 44px;
      background: none;
    }

    .react-calendar__navigation__label {
      pointer-events: none;
      font-family: var(--font-family) !important;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: var(--button-light-active-bg);
    }

    .react-calendar__navigation button[disabled] {
      background-color: var(--button-light-active-bg);
    }

    .react-calendar__month-view__weekdays {
      text-align: center;
      text-transform: uppercase;
      font-weight: var(--bold-weight);
      font-size: 0.8em;
      color: #6F6F6F;
    }

    .react-calendar__navigation__label__labelText, .react-calendar__navigation__arrow {
      color: var(--default-text);
    }

    .react-calendar__month-view__weekdays__weekday {
      padding: 0.5em;

      abbr {
        border-bottom: none !important;
        text-decoration: none !important;
      }
    }

    .react-calendar__month-view__weekNumbers .react-calendar__tile {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75em;
      padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
    }

    .react-calendar__month-view__days__day--weekend {
    }

    .react-calendar__tile {
      max-width: 100%;
      text-align: center;
      padding: 0.3em 0;
      margin: 0.5em 0.385em;
      border-radius: 5px;
      background: var(--wavy-blue);
      color: white;
      flex-basis: 10% !important;
      font-weight: var(--bold-weight);

      &:not(.react-calendar__tile--active) {
        &.calendar--darkest {
          background: #248994;
        }

        &.calendar--darker {
          background: #2CA7B5;
        }

        &.calendar--lighter {
          background: #7CD5DF;
        }

        &.calendar--lightest {
          background: #9CE0E7;
        }
      }
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: #A1A1A1;
      background: none
    }

    .react-calendar__tile:disabled:not(.react-calendar__month-view__days__day--neighboringMonth) {
      background-color: var(--calendar-day-no-listens-bg);
      color: var(--calendar-day-no-listens-text);
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: var(--wavy-blue-light-1);

      &.react-calendar__month-view__days__day--neighboringMonth {
        background: none;
      }
    }

    .react-calendar__tile--now {
    }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
    }

    .react-calendar__tile--hasActive {
      background: var(--accent-pink-light-2);
    }

    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
      background: var(--accent-pink-light-1);
    }

    .react-calendar__tile--active {
      background: var(--accent-pink);
      color: white;
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background: var(--accent-pink-light-1);
    }

    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: #e6e6e6;
    }

    .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
      display: none;
    }
  }

  &.Loading {
    :global {
      .react-calendar__month-view {
        pointer-events: none;
        opacity: 0.25;
      }

      .react-calendar__tile:disabled:not(.react-calendar__month-view__days__day--neighboringMonth) {
        background: none;
        color: var(--default-text);
      }
    }
  }
}
