

.Nav {
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
    display: flex;
  }
}

.NavLink {
  display: inline-block;
  color: var(--default-text);
  text-decoration: none;
  font-size: 18px;
  box-sizing: border-box;

  margin-right: 50px;
  margin-bottom: 15px;
  padding-right: 15px;

  border-right: solid transparent 2px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
    margin-right: 20px;
    padding-right: 0;
    padding-bottom: 2px;
  }

  &.Selected {
    @media only screen and (max-width: 768px) {
      border-bottom: solid var(--wavy-blue) 2px;
      font-weight: var(--bold-weight);
    }
    @media only screen and (min-width: 769px) {
      border-right: solid var(--wavy-blue) 2px;
      font-weight: var(--bold-weight);
    }
  }

  .Label {
    display: flex;
    align-items: center;

    .IconFriend {
      width: 30px;
      :global .IconPath {
        stroke: #6F6F6F;
      }

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .IconFriendAdd {
      width: 30px;
      :global .IconPath {
        stroke: #6F6F6F;
      }

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .IconFriendRequests {
      width: 30px;
      :global .IconSvg {
        height: 12px;
      }
      :global .IconPath {
        fill: #6F6F6F;
      }

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .CountFriendRequests {
      width: 30px;
      transform: translateY(-2px);

      span {
        background-color: var(--danger-red-light-1);
        color: white;
        padding: 1px 7px;
        border-radius: 10px;
        font-size: 13px;
      }
    }

    .IconBlocked {
      width: 30px;
      :global .IconPath {
        fill: #6F6F6F;
      }

      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    :global {
      .IconSvg {
        display: block;
      }
    }
  }
}

.Title {
  color: var(--light-text);
  font-size: 12px;
  text-transform: uppercase;
  margin: 20px 0 15px 30px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}
