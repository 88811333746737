.Popover {
  position: fixed;
  z-index: 100;
  bottom: -120px;
  left: 0;
  height: 120px;
  width: 100%;
  background-color: var(--card-bg);
  border-radius: 10px 10px 0 0;
  border: var(--menu-border);
  box-shadow: 0 0 4px 1px var(--card-shadow);
  box-sizing: border-box;
  padding: 25px;

  animation: appear 0.25s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes appear {
    from {
      bottom: -120px;
    }
    to {
      bottom: 0;
    }
  }
}

.State {
  color: var(--default-text);
}

.Buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  > div:first-of-type {
    margin-right: 20px;
  }

  > * {
    width: 100%;
  }

  span {
    margin: 0 auto;
  }
}
