.Container {
  margin: 50px auto;
  text-align: center;
  font-family: var(--title-font-family);
}

.Counter {
  color: var(--wavy-blue);
  font-size: 55px;
}

.Unit {
  color: var(--default-text);
  font-size: 40px;
}
