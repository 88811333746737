.PasswordForm {
  max-width: 400px;

  .FormField {
    margin-top: 25px;
  }

  .SubmitRegion {
    display: flex;
    justify-content: flex-end;

    * {
      margin-left: 20px;
    }

    .FormSubmit {
      font-size: 14px;
      cursor: pointer;
    }
  }

  label {
    margin-bottom: 10px;
    display: block;
  }
}

.QR {
  margin: 40px 0;
  text-align: center;
  width: 100%;

  img {
    width: 150px;
    height: 150px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .Secret {
    margin-top: 20px;
    display: inline-block;
    padding: 5px 10px;
    font-weight: var(--bold-weight);
    font-size: 14px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    color: #333333; // TODO: DARK
    background-color: #F8F8F8; // TODO: DARK
    border-radius: 5px;
  }
}

.CodeInput {
  margin: 40px 0;
  width: 100%;
  > div {
    margin: 0 auto;
  }
  text-align: center;
}

.Recovery {
  margin-top: 20px;
  display: inline-block;
  padding: 20px 100px;
  line-height: 25px;
  text-align: center;
  word-spacing: 30px;
  font-weight: var(--bold-weight);
  font-size: 14px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #333333; // TODO: DARK
  background-color: #F8F8F8; // TODO: DARK
  border-radius: 10px;
}
