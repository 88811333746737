@import "variables";
// @import url('https://fonts.googleapis.com/css?family=Cabin:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500&display=swap');

@font-face {
  font-family: 'Gilroy-Bold-wavy';
  font-style: normal;
  font-weight: normal;
  src: url(./Fonts/Gilroy-Bold.woff) format('woff');
}

body {
  margin: 0;
  font-family: var(--font-family),
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--background);
  color: var(--default-text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  text-decoration: none;
}

bold, strong, b {
  font-weight: var(--bold-weight);
}
