.Container {
  margin: 50px auto;
  width: calc(100% - 20px);
  max-width: 1300px;
}

.Title {
  font-size: 26px;
  font-weight: var(--bold-weight);
}

.Subtitle {
  font-size: 16px;
}

.Section {
  margin-bottom: 50px;

  &:first-of-type {
    margin-top: 50px;
  }

  p {
    line-height: 24px;
  }
}

.SectionTitle {
  font-size: 20px;
  font-weight: var(--bold-weight);
  margin-bottom: 20px;
}

.Table {
  width: 100%;
  border-spacing: 0;

  th {
    box-sizing: border-box;
    padding: 10px 5px 10px 0;
    border-collapse: collapse;
    border-bottom: solid 2px var(--divider);
    text-align: left;
    text-transform: uppercase;
    font-weight: var(--bold-weight);
  }

  td {
    box-sizing: border-box;
    padding: 10px 5px 10px 5px;
    line-height: 24px;
    vertical-align: top;
    border-bottom: solid 1px var(--divider);

    &:first-of-type {
      font-weight: var(--bold-weight);
    }

    ul {
      margin: 0;
      padding-left: 20px;
    }
  }
}
