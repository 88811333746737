

.ReportForm {
  max-width: 400px;
}

.Question {
  font-weight: var(--bold-weight);
}

.Option {
  color: var(--light-text);
  transition: color .35s ease;
  cursor: pointer;

  &:hover {
    color: var(--default-text);
  }

  &.Loading {
    pointer-events: none;
    color: var(--light-text);
  }
}

.ButtonParent {
  display: flex;
  justify-content: flex-end;
}

.ModalTitle {
  color: var(--danger-red-light-1);
}
