

.Banner {
  margin: 40px auto;
  width: calc(100% - 20px);
  max-width: 1296px;
  text-align: center;
  color: white;

  background-color: #e87304;
  padding: 10px 5px;
  border-radius: 5px;
  box-sizing: border-box;

  a {
    color: white;
    font-weight: var(--bold-weight);
    text-decoration: underline;
  }
}

.Layout {
  margin: 40px auto;
  width: calc(100% - 20px);
  max-width: 1296px;
  display: flex;
  align-items: flex-start;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 769px) {
  }
}

.Sidebar {
  flex-shrink: 0;
  padding: 20px 10px;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
  @media only screen and (min-width: 769px) {
    margin-right: 40px;
    flex-basis: 300px;
  }
}

.Content {
  box-sizing: border-box;
  padding: 0 20px;
  line-height: 1.5;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  @media only screen and (min-width: 769px) {
    flex-grow: 1;
  }

  .Page {
    width: 100%;
    margin-bottom: 30px;
  }

  header {
    margin-bottom: 30px;

    h1 {
      margin-bottom: 0;
    }

    .Subtitle {
      margin-top: 10px;
      color: var(--light-text);
    }
  }
}

.Chapter {
  margin-bottom: 0;
  font-size: .8rem;
  text-transform: uppercase;
  letter-spacing: .2px;
  color: inherit;
  font-weight: var(--bold-weight);
  line-height: 1.2;
  display: block;
  padding: .25em .5em;
}

.NavEntry {
  color: #777088;
  transition: .125s ease-out;
  text-decoration: none;
  opacity: 0.8;
  padding: .25em .5em;
  width: 100%;
  display: block;
  box-sizing: border-box;

  &:hover:not(.Active) {
    color: #463e52;
    opacity: 1;
  }

  &.Active {
    opacity: 1;
    background-color: var(--wavy-blue);
    color: #fff;
    border-radius: 4px;
  }
}

.Separator {
  border-bottom: solid 1px #eeeeee;
  margin: 20px 0;
}

.Spinner {;
  margin: 20px auto;
}

.Source {
    blockquote {
      color: var(--light-text);
      border-left: .25em solid #dfe2e5;
      margin: 0;
      padding: 0 1em;
    }

  code {
    padding: .2em .4em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(27,31,35,.05);
    border-radius: 6px;
  }

  pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 6px;
  }

  pre code {
    display: inline;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: initial;
    border: 0;
    font-size: 100%;
    word-break: normal;
    white-space: pre;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 0 0 5px;
  }

  thead {
    border-bottom: 2px solid var(--divider);
    font-size: 12px;
    text-transform: uppercase;
  }

  th {
    text-align: left;
  }

  th, td {
    padding: 7px 40px 7px 0;
  }

  td {
    border-top: 1px solid var(--divider);
  }
}
