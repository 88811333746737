
$ocean-color: #3364A8;
$no-parallax-screen: 1100px;

.ErrorPage {
  .ErrorContainer {
    display: flex;

    margin-top: 50px;
    margin-bottom: 50px;
    background-color: $ocean-color;
    height: 700px;

    @media only screen and (max-width: $no-parallax-screen) {
      flex-direction: column;
    }
  }

  .LifeBuoy {
    margin-right: 0;
    margin-left: auto;
    height: 700px;
    width: 700px;
    background: url(Error404.svg) no-repeat;
    background-size: auto 650px;
    background-position-y: -40px;
    background-position-x: 100px;

    @media only screen and (max-width: $no-parallax-screen) {
      transform: rotate(90deg) scale(-1);
      margin-left: 0;
      margin-top: 0;
      height: 300px;
      width: 300px;
      background-size: 300px 250px;
      background-position-y: 0;
      background-position-x: 0;
    }
  }

  .ErrorDescription {
    margin-top: 50px;
    margin-left: 50px;

    .ErrorCode {
      background-color: var(--background);
      color: $ocean-color;
      font-size: 175px;
      padding: 20px;
      line-height: 139px;
      display: inline-block;

      @media only screen and (max-width: $no-parallax-screen) {
        font-size: 100px;
        padding: 10px;
        line-height: 100px;
      }
    }

    .ErrorSubtitle {
      font-size: 30px;
      color: var(--background);

      p {
        margin-top: 50px;
      }

      a {
        color: var(--background);
      }

      @media only screen and (max-width: $no-parallax-screen) {
        font-size: 20px;
      }
    }
  }
}
