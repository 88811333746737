.Parent {
  width: 100%;
  margin: 20px 0 40px 0;
}

.Title {
  font-size: 20px;
  width: 100%;
  text-align: center;
  color: var(--light-text);
}

.Action {
  width: 100%;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    margin: 0 15px;
  }
}
