

.EmailVerifyPage {
  width: 100%;
  margin-top: 50px;
  max-width: 800px;

  .EmailVerifyCard {

    .Content {
      padding: 20px;
    }

    .Title {
      display: inline-block;
      font-size: 30px;
      font-weight: var(--bold-weight);
      border-bottom: solid var(--wavy-blue) 2px;
    }

    .LoadingEmpty {
      padding: 50px 0;
    }

    .Red {
      color: #be1e32;
    }
  }
}
