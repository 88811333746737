.MenuFloat {
  position: absolute;
  min-width: 180px;
  overflow: hidden;
  margin-top: 10px;
  z-index: 1;
  border: var(--menu-border);
  right: -5px;
  top: 28px;
}

.MenuItem {
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--default-text);
  text-decoration: none;
  padding: 8px 15px;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 200ms ease;

  &.MenuItemDelete {
    color: var(--danger-red);
    border-top: solid 2px var(--divider);
  }

  &:hover {
    background-color: var(--menu-hover);
  }

  .Icon {
    width: 1em;
    height: 1em;
    margin-right: 15px;
  }

  svg, img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}

.Popover {
  .Subject {
    margin-top: 35px;
  }

  .SubjectSeparator {
    margin: 18px auto 18px auto;
    width: 100%;
    border-radius: 2px;
    height: 2px;
    background-color: var(--divider);
  }

  .Confirmation {
    width: 100%;
    max-width: 100%;
    font-size: 16px;

    .ButtonParent {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;

      > * {
        margin-left: 20px;
      }
    }
  }

  .Action {
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--default-text);
    text-decoration: none;
    padding: 12px 15px;
    box-sizing: border-box;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 200ms ease;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: var(--menu-hover);
    }

    .Icon {
      width: 1em;
      height: 1em;
      margin-right: 15px;
    }

    svg, img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
