

.CardModal {
  .InnerCard {
    opacity: 1;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 100px;
    min-width: 400px;
    padding: 30px;
    box-sizing: border-box;

    &:focus {
      outline: none;
    }

    animation: appear 0.25s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes appear {
      from {
        transform: translateY(50px) translateX(-50%);
        opacity: 0.5;
      }
      to {
        transform: translateY(0) translateX(-50%);
        opacity: 1;
      }
    }
  }

  &.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }

  .Title {
    font-size: 24px;
    font-weight: var(--bold-weight);
    text-align: left;
  }

  .TitleCentered {
    text-align: center;
  }

  .Content {
    margin-top: 20px;
  }
}
