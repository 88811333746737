.FriendsActivityParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.FriendsActivity {
  display: block;
}

.LoadingPlaceholder {
  height: 100px;
}

.Title {
  font-size: 28px;
}

.NoFriends {
  .Note {
    margin-top: 20px;
    font-size: 22px;
    color: var(--light-text);
  }

  .AddFriendsButton {
    margin-top: 25px;
  }
}

.UserActivity {
  margin: 10px 0;
}

.Smaller {
  .Title {
    font-size: 22px;
    font-weight: var(--bold-weight);
    margin-bottom: 15px;
  }

  .NoFriends {
    .Note {
      font-size: 18px;
    }
  }
}

.ViewMoreLink {
  display: flex;
  align-items: center;

  // Icon
  :global .IconSvg {
    margin-left: 8px;
    transition: transform 200ms ease;
  }

  &:hover :global .IconSvg {
    transform: translateX(3px);
  }
}

.FriendCount {
  color: var(--light-text);
}
