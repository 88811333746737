

.SearchParent {
  flex-grow: 1;
  display: flex;

  @media only screen and (max-width: 1000px) {
    align-items: center;
    flex-direction: row-reverse;
  }

  .SearchBarParent {
    width: 100%;
    padding: 10px 100px 10px 70px;
    box-sizing: border-box;

    @media only screen and (max-width: 1000px) {
      display: none;
    }
  }

  .Buttons {
    display: flex;
    align-items: center;
  }

  .SearchMobileButton {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .IconPath {
      transition: fill 200ms ease;
      fill: var(--user-navbar-icon-fill);
    }

    &:hover .IconPath {
      fill: var(--user-navbar-icon-fill-hover);
    }

    @media only screen and (min-width: 1001px) {
      display: none;
    }
  }
}

.SearchMenuFloat {
  position: relative;
  top: 7px;
  width: 100%;
  left: 0;
  overflow: hidden;
  margin-bottom: -100%;
  z-index: 2;

  .SearchMenu {
    width: 100%;
  }
}

.Row {
  display: flex;
  align-items: center;
  background-color: var(--search-bar-result-bg);

  width: 100%;
  text-decoration: none;
  padding: 10px 15px;
  box-sizing: border-box;
  cursor: default;

  &.NoResults {
    color: var(--light-text);
    min-height: 50px;
  }

  &.Loading {
    min-height: 50px;
  }

  .SearchIcon {
    height: 35px;
    width: 35px;
    min-width: 35px;
    border-radius: 4px;
    box-shadow: var(--avatar-shadow);
    box-sizing: border-box;
    margin-right: 15px;
    background-position: center;
    background-size: cover;
  }
}

a.Row {
  color: var(--default-text);
  cursor: pointer;

  &:hover, &.Selected {
    color: var(--default-text);
    background-color: var(--search-bar-result-selected);
  }
}

.MobileOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
}

.MobileOverlayClose {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.45);
}

.MobileTopBar {
  min-height: 60px;
  font-size: 15px;
  color: var(--default-text);
  border-bottom: 1px solid var(--card-border);
  box-sizing: border-box;
  background-color: var(--card-bg);
  width: 100%;

  padding: 10px 10px 10px 10px;
  display: flex;
  align-items: center;

  z-index: 99999;
  position: relative;
}

.MobileCloseButton {
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  margin-right: 20px;
  margin-left: 10px;
  cursor: pointer;

  background-image: url(./SearchClose.svg);
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: 12px 12px;
}

.MobileSearchBox {
  flex-grow: 1;
}

.MobileResultsParent {
  background-color: var(--background);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  margin-top: 60px;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 0 20px;
}

.MobileLoading {
  text-align: center;
  margin: 40px auto;
  color: var(--light-text);
}

.Section {
  width: 100%;
  padding: 20px;
  background-color: var(--search-bar-mobile-bg);
  box-sizing: border-box;
  margin: 30px 0;
}

.SectionTitle {
  color: var(--search-bar-mobile-text);
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-left: 15px;
}
