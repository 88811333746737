.RecoveryForm {
  max-width: 400px;

  .FormField {
    margin-top: 25px;
  }

  .SubmitRegion {
    display: flex;
    justify-content: flex-end;

    * {
      margin-left: 20px;
    }

    .FormSubmit {
      font-size: 14px;
      cursor: pointer;
    }
  }

  label {
    margin-bottom: 10px;
    display: block;
  }
}

.Recovery {
  margin-top: 20px;
  display: inline-block;
  padding: 20px 100px;
  line-height: 25px;
  text-align: center;
  word-spacing: 30px;
  font-weight: var(--bold-weight);
  font-size: 14px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #333333; // TODO: DARK
  background-color: #F8F8F8; // TODO: DARK
  border-radius: 10px;
}
