.Parent {
  width: 450px;
}

.TitleSection {
  padding: 20px 30px;
  border-bottom: solid 1px var(--divider);
  display: flex;
  align-items: center;

  color: var(--danger-red);

  .Icon {
    width: 18px;
    height: 18px;
  }

  .Amount {
    height: 26px;
    font-size: 22px;
    margin-left: 15px;
  }
}

.List {
  max-height: 320px;
  overflow-y: auto;
}

.LoadingItem {
  text-align: center;
  padding: 10px 0;
}

.Item {
  padding: 10px 30px;

  &:first-of-type {
    margin-top: 15px;
  }

  &:last-of-type {
    margin-bottom: 15px;
  }
}
