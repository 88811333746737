.UserMenu {
  width: 100%;

  .Item {
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--default-text);
    text-decoration: none;
    padding: 10px 15px;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color 200ms ease;

    &:hover {
      background-color: var(--menu-hover);
    }

    &.Logout {
      border-top: solid 2px var(--divider);
      color: var(--accent-pink);
    }
  }
}

.FriendRequests {
  background-color: var(--danger-red-light-1);
  color: white;
  padding: 1px 7px;
  border-radius: 10px;
  font-size: 13px;

  position: absolute;
  right: 10px;
  font-weight: normal;
}

.IconFriends {
  margin-right: 14px;
  width: 15px;
  height: 11px;
}

.IconLogout {
  margin-right: 15px;
  width: 14px;
  height: 11px;
}

.IconProfile {
  margin-right: 18px;
  width: 11px;
  height: 12px;
}

.IconSettings {
  margin-right: 17px;
  width: 12px;
  height: 13px;
}

.IconDarkMode {
  margin-right: 16px;
  margin-left: 1px;
  width: 12px;
  height: 13px;
}
