.Card {
  background-color: var(--card-bg);
}

.ElevationFloat {
  box-shadow: 0 3px 10px var(--card-shadow);
  border-radius: 5px;
}

.ElevationModal {
  box-shadow: 0 3px 10px var(--modal-shadow);
  border-radius: 5px;
}

.ElevationSheet {
  box-shadow: 0 1px 5px var(--card-shadow);
  border-radius: 0;
}

.ElevationNone {
}
