

.Page {
  width: 100%;
}

.Banner {
  width: 100%;
  height: 250px;

  background-image: url(./Banner.svg);
  background-color: var(--about-header-bg);
  background-size: auto 500px;
  background-repeat: no-repeat;
  background-position-y: -125px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.PageTitle {
  font-size: 60px;
  font-family: var(--title-font-family);
  color: var(--default-text-inverted);
  background-color: var(--background-inverted);
  padding: 10px 25px 10px 25px;
}
