.IntroBannerUserParent {
  width: 70%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    width: 50%;
  }

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
}

.IntroBannerUser {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 30px;
  min-width: 200px;
  max-width: 500px;

  // Larger devices
  @media only screen and (min-width: 600px) {
    margin-top: 60px;
    padding-right: 100px;
  }

  .IntroText {
    font-size: 38px;
    font-weight: var(--bold-weight);
    margin-right: 10px;

    .Username {
      color: var(--wavy-blue);
    }
  }

  .Listens {
    margin-top: 20px;
    display: flex;

    .ListenCounterParent {
      color: var(--accent-pink-light-2);
      font-size: 24px;
      font-weight: var(--bold-weight);
      flex-grow: 1;

      .ListenCounter {
        color: var(--accent-pink);
        font-size: 52px;
        margin-left: 15px;
      }
    }

    .ProfileLink {
      width: 43px;
      height: 43px;
      background: var(--home-profile-link-icon) no-repeat;
      background-color: var(--home-profile-link-icon-bg);
      background-size: 43px 43px;
      margin-top: 10px;
      border-radius: 100%;

      box-shadow: 0 0 0 2px var(--accent-pink-transparent);
      transition: box-shadow 200ms ease-in-out;

      &:hover {
        box-shadow: 0 0 0 5px var(--accent-pink-light-3);
      }
    }
  }
}

.UserArtistBlob {
  width: 400px;
  height: 400px;
  background-color: #A5EBF2;
  margin-top: 75px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  transition: background 400ms ease;

  mask: url(./ArtistBlobMask.svg);
  mask-size: 400px 400px;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}
