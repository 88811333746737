.TrackHistoryList {
  @media only screen and (max-width: 768px) {
  }
  @media only screen and (min-width: 769px) {
    margin: 20px auto 0 auto;
    max-width: 705px;
  }
}

.HistoryListRow:not(.Live):first-of-type {
  @media only screen and (min-width: 769px) {
    display: none;
  }
}

.MobileTitle {
  @media only screen and (max-width: 768px) {
    color: var(--default-text);
    font-size: 20px;
    font-weight: var(--bold-weight);
    margin: 30px 0 10px 0;
  }
  @media only screen and (min-width: 769px) {
    display: none;
  }
}

.Live {
}

.Loading {
  opacity: 0.3;
}
