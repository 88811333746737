.Container {
  margin: 0 auto;
  box-sizing: border-box;
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    padding: 30px;
    align-items: unset;
  }
}

.Logo {
  margin-bottom: 10px;
  transform: translateX(-10px);
  height: 120px;

  background-image: var(--logo-default);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  width: 320px;

  @media only screen and (max-width: 1000px) {
    height: 100px;
    width: 250px;
  }
}

.Slogan {
  font-family: var(--title-font-family);
  font-size: 48px;
  text-align: center;
  width: 100%;

  @media only screen and (max-width: 1000px) {
    text-align: left;
    font-size: 36px;
  }
}

.UnderWavy {
  display: inline-block;
  height: 69px;
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-position-y: bottom;
  background-size: calc(100% - 3px);
  background-image: url(./UnderWave.svg);

  @media only screen and (max-width: 1000px) {
    height: 50px;
  }
}

.Subtitle {
  margin: 20px 0;
  font-size: 24px;
  max-width: 500px;
  text-align: center;

  @media only screen and (max-width: 1000px) {
    font-size: 18px;
    text-align: left;
    margin-top: 30px;
  }
}

.JoinButton {
  margin: 20px auto 50px auto;

  @media only screen and (max-width: 1000px) {
    width: 100%;
    margin: 30px 0;
    display: block;
    text-align: center;
  }
}

.BlobKentucky {
  position: absolute;
  bottom: -220px;
  left: 100px;
  z-index: -100;
  width: 475px;
  height: 454px;
  background-image: url(./BlobKentucky.svg);
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.BlobLouisiana {
  position: absolute;
  top: -40px;
  left: 0;
  z-index: -100;
  width: 286px;
  height: 425px;
  background-image: url(./BlobLouisiana.svg);
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.BlobGeorgia {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -100;
  width: 216px;
  height: 480px;
  background-image: url(./BlobGeorgia.svg);
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}
