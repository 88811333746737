
.UserNavbar-Container {
  $height: 35px;
  $border-thickness: 2px;

  background-color: var(--user-navbar-bg);
  transition: background-color 200ms ease;

  height: 35px;
  display: inline-flex;
  flex-direction: row;
  border-radius: 4px;
  cursor: pointer;

  &:hover, &.Open {
    background-color: var(--user-navbar-bg-hover);
  }

  .Username {
    line-height: $height;
    margin: 0 15px;
  }
}

.UserMenuFloat {
  position: absolute;
  top: 55px;
  width: 200px;
  overflow: hidden;
  border: var(--user-navbar-menu-border);
}

.NavBar-Right {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  flex-direction: row;
  height: 60px;
}

.NavBar-DarkModeToggle {
  margin-left: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover .IconPath {
    fill: var(--user-navbar-icon-fill-hover);
  }
}
