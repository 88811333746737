

.ListCard {
  padding: 50px 50px;
  display: flex;
  align-items: center;
}

.Content {
  display: block;
  width: 100%;
}

.TitleBar {
  margin-bottom: 20px;
  height: 52px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    display: none;
  }

  .Title {
    color: var(--default-text);
    font-weight: var(--bold-weight);
    font-size: 22px;
    margin-left: 50px;
    flex-grow: 1;
  }

  .TitleRight {
    display: flex;
    align-items: center;

    > * {
      margin-left: 20px;
    }
  }

  .AddButton {
    @media only screen and (max-width: 1000px) {
      display: none;
    }
  }

  .SearchBox {
    width: 350px;
  }

  .FriendCount {
    color: var(--light-text);
  }
}

.Grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 40px 20px;

  @media only screen and (max-width: 768px) {
    margin-top: 20px;
  }
}

.Skeleton {
  height: 60px;
}

.Placeholder {
  width: 100%;
  text-align: center;
  color: var(--light-text);
}
