
.Grid {
  display: flex;
  flex-wrap: wrap;

  @media only screen and (min-width: 769px) {
    width: 300px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.Cell {
  background-color: var(--profile-grid-cell-bg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &.Filled:hover {
    @media only screen and (min-width: 769px) {
      filter: brightness(70%);
    }
  }

  @media only screen and (min-width: 769px) {
    flex: 0 0 100px;
    height: 100px;
    width: 100px;
  }
  @media only screen and (max-width: 768px) {
    flex: 0 0 33%;
    height: 30vw;
  }
}

.FocusedImage {
  width: 300px;
  height: 300px;
  background-color: var(--profile-grid-cell-bg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .Title {
    color: #FFFFFF;
    max-width: calc(300px - 60px);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 20px;
    font-weight: var(--bold-weight);
  }

  .Plays, .SubTitle {
    max-width: calc(300px - 60px);
    color: #FFFFFF;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
    font-size: 16px;
  }

  .SubTitle {
    margin-bottom: 10px;
    font-weight: var(--bold-weight);
  }

  .Info {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }
}

.ViewMore {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  .ViewMoreLink {
    display: flex;
    align-items: center;

    // Icon
    :global .IconSvg {
      margin-left: 8px;
      transition: transform 200ms ease;
    }

    &:hover :global .IconSvg {
      transform: translateX(3px);
    }
  }
}

.Loading {
  opacity: 0.3;
  pointer-events: none;
}

.GridHistoryTitle {
  font-size: 20px;
  height: 24px;
  font-weight: var(--bold-weight);
  margin: 20px 0 10px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .LeftSide {
    display: flex;
    align-items: center;
  }
}

.GridHistoryParent {
  @media only screen and (min-width: 769px) {
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.SaveAsDefault {
  cursor: pointer;
  font-size: 13px;
  color: var(--wavy-blue);
  margin-right: 10px;
}
