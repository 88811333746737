

.SearchBox {
  margin-top: 1px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px 10px 60px;

  border: none;
  border-radius: 5px;

  background-image: var(--search-box-icon-placeholder);
  background-repeat: no-repeat;
  background-position: 22px 13px;
  background-size: 15px 15px;

  font-family: inherit;

  background-color: var(--search-box-bg);
  font-size: 16px;
  resize: none;
  color: var(--default-text);

  &:focus {
    background-color: var(--search-box-bg-focus);
    border-bottom-color: var(--wavy-blue);
    outline: none;
  }
}
