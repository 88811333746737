.CurrentValue {
  position: relative;
  display: flex;
  align-items: center;
  border: solid var(--button-regular-outline-border) 1px;
  border-radius: 5px;
  padding: 6px 11px;
  cursor: pointer;
  flex-shrink: 0;

  transition: background-color 0.2s;
  user-select: none;

  &:not(.Disabled):not(.Active):hover {
    background-color: var(--button-regular-outline-active-bg);
  }

  &.Disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.Active {
    cursor: default;
    box-shadow: 0 0 5px #c7c7c7;
    background-color: var(--button-regular-outline-active-bg);
  }

  .Icon {
    margin-right: 8px;
    height: 14px;
    width: 14px;
  }
}


.MenuFloat {
  position: absolute;
  min-width: 150px;
  overflow: hidden;
  margin-top: 10px;
  z-index: 1;
  border: var(--menu-border);

  right: 0;
  top: 24px;
}


.MenuItem {
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--default-text);
  text-decoration: none;
  padding: 8px 15px;
  box-sizing: border-box;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 200ms ease;

  &:hover {
    background-color: var(--menu-hover);
  }

  svg {
    margin-right: 15px;
  }
}
