.Container {
  margin: 50px auto;
  width: calc(100% - 20px);
  max-width: 900px;
}

.Title {
  font-size: 26px;
  font-weight: var(--bold-weight);
}

.Subtitle {
  font-size: 16px;
}

.Section {
  margin-bottom: 50px;

  &:first-of-type {
    margin-top: 50px;
  }

  p {
    line-height: 24px;
  }
}

.SectionTitle {
  font-size: 20px;
  font-weight: var(--bold-weight);
  margin-bottom: 20px;
}
