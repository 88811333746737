.Background {
  width: 100%;
  z-index: 1;
  position: relative;
  padding: 75px 0;
  box-sizing: border-box;

  // Swapped colors
  background-color: var(--background-dark);
  color: var(--default-text-dark);
}

.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: calc(100% - 50px);
  max-width: 1296px;

  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    width: calc(100% - 50px);
  }
}

.Description {
  @media only screen and (min-width: 1201px) {
    margin-right: 150px;
  }

  .Headline {
    font-size: 40px;
    font-family: var(--title-font-family);

    @media only screen and (max-width: 1200px) {
      font-size: 30px;
    }
  }

  .Blue {
    color: var(--wavy-blue);
  }

  .Text {
    font-size: 20px;
  }
}

.Illustration {
  flex-shrink: 0;
  width: 633px;
  height: auto;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin: 0 auto 50px;
  }
}
