

.Form {
  background-color: var(--profile-edit-bg);
  outline: solid var(--profile-edit-bg) 10px;
  margin-top: 25px;

  .FormField:not(:last-of-type) {
    margin-bottom: 10px;
  }

  input[type=text], input[type=email], input[type=password], textarea, select {
    background-color: var(--profile-edit-bg);
  }
}

.SpotifyCheckbox {
  width: 13px;
  height: 13px;
  border-radius: 2px;
  border: solid 1px #E4E4E4;
  box-sizing: border-box;

  &[aria-checked=true] {
    background-image: url(../../../Images/SpotifyCheck.svg);
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
  }
}

.CheckboxField {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  max-width: 200px;

  * {
    cursor: pointer;
  }
}

.DiscordCheckbox {
  width: 13px;
  height: 13px;
  border-radius: 2px;
  border: solid 1px #E4E4E4;
  box-sizing: border-box;

  &[aria-checked=true] {
    background-image: url(../../../Images/DiscordCheck.svg);
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
  }
}

.SubmitRegion {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.CancelButton {
  margin-right: 15px;
}
