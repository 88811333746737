.TrendingArtistsRow {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 75px 0;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  .ArtistParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    @media only screen and (max-width: 768px) {
      flex-direction: row;
      justify-content: space-evenly;
      margin: 25px 0;
      word-break: break-word;
      width: 100%;
    }
  }

  .ArtistIcon {
    width: 150px;
    min-width: 150px;
    height: 150px;
    border-radius: 100%;

    background-size: cover;
  }

  .ArtistName {
    width: 100%;
    max-width: 150px;
    text-align: center;

    margin-top: 15px;
    font-size: 18px;
    font-weight: var(--bold-weight);

    @media only screen and (max-width: 768px) {
      text-align: left;
      margin-top: 0;
    }
  }
}
