.CommentParent {
  margin-bottom: 35px;
}

.CommentView {
  display: flex;

  &.Highlighted {
    background: var(--profile-comment-highlight);
    outline: solid var(--profile-comment-highlight) 10px;
    animation: fade 1s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fade {
      from {
        background-color: var(--profile-comment-highlight);
        outline-color: var(--profile-comment-highlight);
      }
      to {
        background-color: transparent;
        outline-color: transparent;
      }
    }
  }
}

.Content {
  flex-grow: 1;
}

.Avatar {
  margin-right: 15px;
}

.Headline {
  display: inline-flex;
  align-items: center;
  margin-top: 2px;

  @media only screen and (max-width: 768px) {
    display: inline-block;
  }

  .Username {
    display: inline-flex;
    align-items: center;
    margin-right: 15px;
    font-weight: var(--bold-weight);
    font-size: 18px;
  }

  .UsernameLink {
    color: var(--default-text);
  }

  .Details {
    display: inline;
  }

  .Date {
    font-size: 14px;
    color: var(--light-text);
  }
}

.CommentBox {
  border-bottom: solid 2px var(--comment-divider);
  padding-bottom: 10px;
}

.BottomSection {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Actions {
  display: flex;
  height: 16px;
  align-items: center;

  .LikeSection {
    display: flex;
    align-items: center;
    height: 16px;

    .Button {
      cursor: pointer;
    }

    .Liked, .Unliked {
      padding: 0 5px;
      margin-left: 5px;
      margin-top: 1px;
      cursor: pointer;
    }

    .Liked {
      color: #FF4F4F;
    }

    .Unliked {
      color: var(--light-text);
    }

    .UnlikedButton {
      &:hover {
        :global {
          path {
            fill: #FF4F4F;
          }
        }
      }
    }

    .LikedButton {
    }
  }

  .Reply {
    margin-left: 18px;
    color: var(--light-text);
    cursor: pointer;

    &:hover {
      transition: color 200ms ease;
      color: var(--default-text);
    }
  }
}

.MenuParent {
  position: relative;
}

.MenuIcon {
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-left: 10px;

  &:hover :global .IconPath {
    fill: var(--default-text);
  }

  &.Open :global .IconPath {
    fill: var(--default-text);
  }

  @media only screen and (max-width: 768px) {
    opacity: 1;
  }

  @media only screen and (min-width: 769px) {
    &:not(.Open) {
      opacity: 0;
    }
  }
}

.CommentView:hover .MenuIcon {
  opacity: 1;
}

.MenuItem {
  display: block;
  width: 100%;
  color: var(--default-text);
  text-decoration: none;
  padding: 8px 15px;
  box-sizing: border-box;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 200ms ease;

  &:hover {
    background-color: var(--menu-hover);
  }

  svg {
    margin-right: 15px;
  }

  &.DeleteItem {
    color: var(--danger-red);

    svg {
      fill: var(--danger-red) !important;
    }
  }
}


.MenuFloat {
  position: absolute;
  min-width: 125px;
  overflow: hidden;
  margin-top: 10px;
  z-index: 1;
  border: var(--menu-border);

  @media only screen and (max-width: 768px) {
    left: -100px;
  }
  @media only screen and (min-width: 769px) {
    left: calc(100% - 15px);
  }
}

.ModalTitle {
  color: var(--danger-red);
}

.DeleteConfirm {
  max-width: 400px;

  .DeletePreview {
    color: var(--light-text);
    border-left: solid 2px #DDDDDD;
    margin: 20px 0;
    padding: 5px 10px 7px 15px;
  }

  .ButtonParent {
    display: flex;
    justify-content: flex-end;

    > * {
      margin-left: 20px;
    }
  }
}

.Replies {
  padding-left: 65px;

  > * {
    margin: 20px 0;
  }
}

.NewReplyArea {
  margin: 20px 0 30px 0;

  display: flex;

  .Avatar {
    margin-right: 15px;
  }

  .ReplyBox {
    flex-grow: 1;
  }
}

.LikeLoginPopup {
  display: flex;
  flex-direction: column;
  align-items: center;

  .LikeLoginPopupIcon {
    width: 30px;
    height: auto;
  }

  .PopupTitle {
    text-align: center;
    width: 100%;
    margin: 20px 0 30px;
    font-size: 24px;
    font-weight: var(--bold-weight);
  }

  .PopupDescription {
    text-align: center;
    width: 100%;
    font-size: 18px;
    color: #c7c7c7;
  }

  .PopupButtons {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 10px;

    > * {
      margin: 0 10px;
    }
  }
}

.LikesModal {
  padding: 0 !important;
}

.LikesModalContent {
  margin-top: 0 !important;
}

.CollapseButton {
  cursor: pointer;
  color: var(--light-text);

  .Icon {
    margin-left: 5px;
  }
}
