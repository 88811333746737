.Avatar {
  background-color: var(--avatar-background);
  border-radius: 4px;
  box-shadow: var(--avatar-shadow);
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
  background-image: url(../../../Images/Avatar/Default.svg);
}

.Editable {
  cursor: pointer;
  transition: filter 200ms ease;

  &:hover {
    filter: brightness(60%);
  }
}

.Rounder {
  border-radius: 10px;
}

.Disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
}
