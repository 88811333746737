

.PictureForm {
  padding-top: 15px;

  .DefaultPictureSection {
    width: 100%;
    display: flex;
    justify-content: center;

    .DefaultPicture {
      cursor: pointer;
    }
  }

  .BrowseInput {
    display: none;
  }

  .ChooseFileButton {
    margin-top: 20px;
    margin-bottom: 10px;
    display: inline-flex;

    &.Disabled {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .UploadPreview {
    background-color: var(--background);
    box-shadow: var(--avatar-shadow);
    background-position: center;
    background-size: cover;

    width: 150px;
    height: 150px;
    border-radius: 10px;
  }

  .SectionSeparator {
    width: 100%;
    font-size: 20px;
    text-align: center;
    margin: 15px 0;
    color: var(--default-text);
  }

  .UploadError {
    color: var(--danger-red);
    max-width: 400px;
    margin-top: 40px;
    text-align: center;
  }

  .UploadPictureSection {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .ActionParent {
    box-sizing: border-box;
    display: block;
    padding-left: 40px
  }

  .UploadNotice {
    margin-top: 5px;
    max-width: 250px;
  }

  .FormSubmit {
    font-size: 16px;
  }

  .ErrorSection {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
