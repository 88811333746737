.Parent {
  display: flex;
  justify-content: center;
}

.Digit {
  flex: 1;
  font-size: 40px !important;
  max-width: 50px !important;
  height: 60px !important;
  display: block;
  text-align: center;

  &:not(:last-child) {
    margin-right: 10px;
  }
}
