.Container {
  color: var(--default-text);
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 20px;
  position: relative;
  box-sizing: border-box;
}

.Info {
  margin-left: 20px;
  max-width: calc(100% - 118px);
}

.Time {
  font-size: 12px;
  color: var(--light-text);
}

.Medium {
  font-weight: var(--bold-weight);
}

.Read {
  opacity: 0.75;
}

.Actions {
  position: absolute;
  right: 30px;
  display: flex;
  align-items: center;
}

.Delete {
  width: 26px;
  height: 26px;
  margin-left: 30px;
  opacity: 0;
  background: url(./DeleteIcon.svg) no-repeat;
  background-size: 20px 20px;
  background-position: 3px 3px;
  border-radius: 5px;

  &:hover {
    background-color: var(--notification-delete-hover);
  }
}

.Chip {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--wavy-blue);
}
