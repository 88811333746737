

.ListCard {
  padding: 16px 26px;
}

.ArtistIcon {
  border-radius: 100%;
  background-size: cover;
}

.SummaryColumn {
  width: 300px;
  padding: 0 10px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }

  .UserInfo {
    display: flex;
    align-items: center;

    .Username {
      font-size: 20px;
      font-weight: var(--bold-weight);
      margin-left: 20px;
      color: var(--default-text);
    }
  }
}


.HistoryPage {
  display: flex;
  width: 100%;
  padding-top: 40px;

  @media only screen and (max-width: 768px) {
    display: block;
    padding-top: 20px;
  }
}

.Navigation {
  margin-left: 26px;
  margin-bottom: 30px;
  height: 52px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    display: block;
    height: unset;
    margin: 30px 0;
  }

  a {
    display: inline-block;
    padding: 0;
    margin-right: 40px;
    border-bottom: solid transparent 2px;
    color: var(--default-text);
    font-size: 20px;

    &.Selected, &:hover {
      border-bottom-color: var(--wavy-blue);
    }

    &.Selected {
      font-weight: var(--bold-weight);
    }
  }

  .NavigationRight {
    display: flex;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }

  .SearchBox {
    width: 280px;

    @media only screen and (max-width: 768px) {
      flex-grow: 1;
    }
  }

  .DateRange {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 768px) {
      margin: 0 20px;
      width: unset;
    }
  }

  .EditButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    * {
      margin-left: 20px;
    }

    @media only screen and (max-width: 769px) {
      width: unset;
    }
  }
}

.ListColumn {
  flex-grow: 1;
  max-width: 800px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
}

.Skeleton {
  min-height: 1000px;
}

.Loading {
  opacity: 0.3;
  pointer-events: none;
}

.Total {
  text-align: center;
  color: var(--light-text);
}

.Pagination {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    padding: 20px 8px;
  }
  @media only screen and (min-width: 769px) {
    padding: 20px 30px;
  }

  .PreviousLink {
    color: var(--light-text);
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: flex-start;

    @media only screen and (max-width: 768px) {
      font-size: 0;
      width: 20px;
    }

    :global {
      .IconSvg {
        margin-left: 0;
        margin-right: 10px;

        @media only screen and (max-width: 768px) {
          width: 10px;
        }
      }
    }
  }

  .NextLink {
    color: var(--light-text);
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: flex-end;

    @media only screen and (max-width: 768px) {
      font-size: 0;
      width: 20px;
    }

    :global {
      .IconSvg {
        margin-left: 10px;
        margin-right: 0;

        @media only screen and (max-width: 768px) {
          width: 10px;
        }
      }
    }
  }
}

.PageNumberList {
  text-align: center;

  .PageNumberSelected {
    color: var(--default-text-dark);
    background-color: var(--wavy-blue);
    padding: 2px 5px;
    min-width: 15px;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    margin: 0 4px;
  }

  .PageNumberLink {
    color: var(--light-text);
    padding: 2px 5px;
    min-width: 15px;
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    margin: 0 4px;

    &:hover {
      background-color: var(--pagination-hover);
    }
  }

  .PageNumberDots {
    color: var(--light-text);
    margin: 0 5px;
  }
}

.Calendar {
  margin-top: 30px;
}

.DateFilterTitle {
  margin-bottom: 20px;
  margin-left: 26px;

  color: var(--default-text);
  font-weight: var(--bold-weight);
  font-size: 20px;

  .Count {
    color: var(--light-text);
    display: inline-flex;
    align-items: center;
  }

  .ListenIcon {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    transform: scale(0.9);
  }
}

.ClearFilter {
  width: 100%;
  text-align: center;
  color: var(--light-text);
  display: block;
  margin: 15px 0 5px 0;
}

.Placeholder {
  width: 100%;
  text-align: center;
  color: var(--light-text);
}

.MobileEditing {
  padding-bottom: 150px;
}
