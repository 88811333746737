.AccountSettings {
  .UnverifiedEmail {
    color: #ff843b !important;

    .VerifyLink {
      font-weight: var(--bold-weight);
      cursor: pointer;
    }
  }

  .LoadingEmpty {
    padding: 80px 0;
  }
}

.NewPasswordForm, .NewEmailForm, .DeleteForm, .NewUsernameForm {
  .FormField {
    margin-top: 25px;
  }

  .SubmitRegion {
    display: flex;
    justify-content: flex-end;

    * {
      margin-left: 20px;
    }

    .FormSubmit {
      font-size: 14px;
      cursor: pointer;
    }
  }

  label {
    margin-bottom: 10px;
    display: block;
  }

  .NewEmailWarning, .NewUsernameWarning {
    max-width: 400px;
  }
}
