.DeleteForm {
  max-width: 400px;

  .FormField {
    margin-top: 25px;
  }

  .SubmitRegion {
    display: flex;
    justify-content: flex-end;

    * {
      margin-left: 20px;
    }

    .FormSubmit {
      font-size: 14px;
      cursor: pointer;
    }
  }

  label {
    margin-bottom: 10px;
    display: block;
  }
}


.Danger {
  font-weight: var(--bold-weight);
  color: var(--danger-red);
}
