



.WelcomePage {
  width: 100%;
  margin-top: 50px;
  max-width: 700px;

  .WelcomeCard {

    .Content {
      padding: 20px;
    }

    .Title {
      display: inline-block;
      font-size: 30px;
      font-weight: var(--bold-weight);
      border-bottom: solid var(--wavy-blue) 2px;
    }

    .Subtitle {
      margin-top: 30px;
      font-size: 20px;

      .Email {
        color: var(--wavy-blue);
      }
    }

    .Separator {
      padding-top: 10px;
      margin-bottom: 30px;
      border-bottom: solid 1px #CCCCCC;
    }

    .WelcomeNewEmailForm {
      .FormField {
        margin-bottom: 10px;
      }

      input {
        max-width: 300px;
        margin-right: 20px;
      }

      .SubmitRegion {
        justify-content: flex-start;
      }
    }

    .AuthError {
      margin-right: 0;
      margin-left: 0;
      max-width: 100%;
    }
  }
}
