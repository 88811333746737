

.ModalTitle {
  color: var(--danger-red-light-1);
}

.Content {
  max-width: 400px;
}

.Question {
  font-weight: var(--bold-weight);
}

.ButtonParent {
  display: flex;
  justify-content: flex-end;

  * {
    margin-left: 20px;
  }
}
