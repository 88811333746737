.FloatingMenu {
  position: absolute;
  top: 55px;
  width: 400px;
  box-sizing: border-box;
  border: var(--user-navbar-menu-border);

  @media only screen and (max-width: 768px) {
    width: calc(100% - 20px);
    left: 10px;
  }
}

.Card {
}

.Header {
  padding: 20px;
  border-bottom: solid 1px var(--divider);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Title {
    color: var(--light-text);
    text-transform: uppercase;
    font-weight: var(--bold-weight);
    font-size: 14px;
  }

  .MarkAsRead {
    color: var(--light-text);
    font-size: 14px;
    cursor: default;

    &.Enabled {
      color: var(--wavy-blue);
      cursor: pointer;
    }
  }
}

.Content {
}

.Empty {
  padding: 25px 0;
  text-align: center;
  color: var(--light-text);
  font-weight: var(--bold-weight);
}

.List {
  max-height: calc(80px * 4);
  overflow-y: auto;

  .Item {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    border-bottom: solid 1px var(--divider);
  }

  .Read {
    opacity: 0.75;
  }
}

.Filler {
  height: 50px;
}

.Footer {
  box-shadow: 0 0 0 1px var(--divider);
  background-color: inherit;
  width: 100%;
  text-align: center;
  padding: 10px 0;
}

.Spinner {
  background-color: inherit;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  color: var(--light-text);
}
