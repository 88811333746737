.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  box-sizing: border-box;

  @media only screen and (min-width: 1001px) {
    margin: 0 auto 20px auto;
    max-width: 780px;
  }
  @media only screen and (max-width: 1000px) {
    margin: 0 auto 20px auto;
    max-width: calc(100% - 40px);
  }
}

.Title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0;

  .TitleText {
    font-weight: var(--bold-weight);
    font-size: 22px;
  }
}

.Empty {
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.Loading {
  width: 100%;
  margin-bottom: 40px;
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.Next {
  margin: 40px 0;

  * {
    display: block;
  }
}

.List {
  width: 100%;

  .Item {
    width: 100%;
    height: 80px;
    align-items: center;
    box-sizing: border-box;
    background-color: var(--card-bg);

    &:not(:last-of-type) {
      border-bottom: solid 1px var(--divider);
    }

    &:hover .DeleteIcon {
      opacity: 1;
    }
  }
}
