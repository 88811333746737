.GridHistorySection {
  margin-top: 20px;
  @media only screen and (min-width: 769px) {
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}
