

.Footer {
  // Swapped
  background-color: var(--background-dark);
  color: var(--default-text-dark);

  margin-top: 30px;
  width: 100%;
  padding: 10px 0;
  text-align: center;

  .FooterContent {
    display: block;
  }

  .FooterLogo {
    display: inline-block;
    width: 222px;
    height: 48px;
    background: url(../../../Images/wavy-logo-white.svg) no-repeat;
    background-size: auto 42px;
    margin: 20px 0;
  }

  .AboutLinks a {
    font-size: 24px;
    color: var(--default-text-dark);
    display: inline-flex;
    border-bottom: solid transparent 2px;
    box-sizing: border-box;

    margin-right: 20px;
    margin-left: 20px;

    &:hover {
      border-bottom: solid var(--default-text-dark) 2px;
    }
  }

  .SocialLinks {
    height: 30px;
    text-align: center;
    display: inline-flex;
    margin: 20px 0;
    font-size: 0;

    .Twitter {
      background: url(../Home/Icons/Twitter.svg) no-repeat 5px 5px;
      background-size: auto 25px;
      width: 30px;
      height: 25px;
      margin: 0 10px;
      padding: 5px;
      transition: filter 200ms ease;

      &:hover {
        filter: brightness(60%);
      }
    }

    .Instagram {
      background: url(../Home/Icons/Instagram.svg) no-repeat 5px 5px;
      background-size: auto 25px;
      width: 25px;
      height: 25px;
      margin: 0 10px;
      padding: 5px;
      transition: filter 200ms ease;

      &:hover {
        filter: brightness(60%);
      }
    }

    .Discord {
      background: url(../Home/Icons/Discord.svg) no-repeat 5px 5px;
      background-size: auto 25px;
      width: 25px;
      height: 25px;
      margin: 0 10px;
      padding: 5px;
      transition: filter 200ms ease;

      &:hover {
        filter: brightness(60%);
      }
    }
  }

  .TermsLinks a {
    font-size: 18px;
    transition: color ease 0.35s;
    color: #888888;
    display: inline-flex;

    margin-right: 15px;
    margin-left: 15px;
    margin-top: 5px;

    &:hover {
      color: var(--default-text-dark);
    }
  }

  .Copyright {
    margin: 15px 0 15px 0;
    font-size: 14px;
    color: #888888;
  }
}
