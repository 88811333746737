

.CurrentValue {
  color: var(--light-text);
  transition: color 200ms ease;

  font-size: 13px;
  cursor: pointer;
  position: relative;

  display: flex;
  align-items: center;

  &:hover, &.Opened {
    color: var(--default-text);
  }
}

.Arrow {
  width: 7px;
  height: 5px;
  display: inline-block;
  margin-left: 5px;
  transform: rotate(0deg);
  transition: transform 300ms ease;

  &.Opened {
    transform: rotate(180deg);
  }
}

.ArrowPath {
  fill: var(--light-text);
}

.Menu {
  position: absolute;
  overflow: hidden;
  width: 100px;
  top: 25px;
  right: -5px;
  z-index: 2;
  border: var(--menu-border);

  .Item {
    color: var(--light-text);
    display: block;
    width: 100%;
    text-decoration: none;
    padding: 12px 10px 12px 15px;
    box-sizing: border-box;
    transition: background-color 200ms ease;

    &:hover {
      background-color: var(--menu-hover);
    }

    &.Selected {
      color: var(--wavy-blue);
    }
  }
}
