

.Section {
  margin: 50px 0;
  min-height: 200px;
}

.Title {
  font-size: 20px;
  font-weight: var(--bold-weight);
  margin: 20px 0 10px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.NewCommentArea {
  margin: 20px 0 30px 0;

  display: flex;

  .Avatar {
    margin-right: 15px;
  }

  .CommentBox {
    flex-grow: 1;
  }
}

.NewCommentDisabled {
  margin: 20px 0 30px 0;
  background-color: var(--profile-comment-disabled);
  text-align: center;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;

  a {
    font-weight: var(--bold-weight);
    color: var(--default-text);
  }
}

.ErrorContent {
  max-width: 400px;

  .ButtonParent {
    display: flex;
    justify-content: flex-end;

    > * {
      margin-left: 20px;
    }
  }
}

.Next {
  margin: 40px 0;
  display: flex;
  justify-content: center;
}
