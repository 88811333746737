

.SectionIntro {
  background-color: var(--about-header-bg);

  @media only screen and (min-width: 1001px) {
    padding: 150px 0;
  }
  @media only screen and (max-width: 1000px) {
    padding: 50px 0;
  }

  .Columns {
    display: flex;
    width: 100%;
    align-items: center;

    > * {
      @media only screen and (min-width: 1001px) {
        width: 100%;
      }
      @media only screen and (max-width: 1000px) {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
      }
    }

    @media only screen and (max-width: 1000px) {
      display: block;
    }
  }

  .Headline {
    @media only screen and (min-width: 1001px) {
      font-size: 45px;
      font-family: var(--title-font-family);
      padding-right: 10%;
      box-sizing: border-box;
    }
    @media only screen and (max-width: 1000px) {
      font-size: 30px;
      font-family: var(--title-font-family);
      margin-bottom: 20px;
    }
  }

  .LongForm {
    font-size: 20px;
    line-height: 30px;
  }
}

.SectionFeatures {
  background-color: var(--background-alt);

  @media only screen and (min-width: 1001px) {
    padding: 100px 0;
  }
  @media only screen and (max-width: 1000px) {
    padding: 50px 20px;
  }

  .Title {
    @media only screen and (min-width: 1001px) {
      font-size: 35px;
      font-family: var(--title-font-family);
      margin-bottom: 20px;
    }
    @media only screen and (max-width: 1000px) {
      font-size: 30px;
      font-family: var(--title-font-family);
      margin-bottom: 20px;
    }
  }

  .Columns {
    width: 100%;

    @media only screen and (min-width: 1001px) {
      display: flex;
      align-items: center;
    }
    @media only screen and (max-width: 1000px) {
      display: block;
    }

    > * {
      @media only screen and (min-width: 1001px) {
        width: 50%;
      }
      @media only screen and (max-width: 1000px) {
        width: 100%;
      }

      &:first-of-type {
        @media only screen and (min-width: 1001px) {
          padding-right: 30px;
        }
        @media only screen and (max-width: 1000px) {
        }
      }
    }
  }

  .FeatureCard {
    box-sizing: border-box;
    margin: 30px 0;
    background: #FEFEFE;
    border-radius: 8px;
    border: solid 1px #ECECEC;
    color: var(--default-text-orig);

    @media only screen and (min-width: 1001px) {
      padding: 25px 20px;
      font-size: 21px;
    }
    @media only screen and (max-width: 1000px) {
      padding: 20px 20px;
      font-size: 16px;
    }

    display: flex;
    align-items: center;
    position: relative;

    .Soon {
      position: absolute;
      top: 5px;
      left: -10px;
      text-transform: uppercase;
      color: var(--default-text-dark);
      background-color: var(--danger-red-light-1);
      border-radius: 4px;
      font-weight: var(--bold-weight);
      transform: rotate(-20deg);

      @media only screen and (min-width: 1001px) {
        font-size: 12px;
        padding: 4px 20px;
      }
      @media only screen and (max-width: 1000px) {
        font-size: 10px;
        padding: 4px 15px;
      }
    }

    img {
      margin-left: 10px;
      margin-right: 30px;

      @media only screen and (min-width: 1001px) {
        height: 40px;
        width: 40px;
      }
      @media only screen and (max-width: 1000px) {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.Container {
  margin: 0 auto;
  width: calc(100% - 20px);
  max-width: 1000px;
}

