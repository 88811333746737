

.SettingsPage {
  display: flex;
  width: 100%;
  margin-top: 50px;
  max-width: 1100px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 0;
  }

  .Navigation {
    min-width: 200px;
    padding-top: 50px;

    a {
      display: block;
      color: var(--default-text);
      text-decoration: none;
      font-size: 20px;
      text-align: right;
      box-sizing: border-box;

      padding-right: 15px;
      margin-right: 50px;
      margin-bottom: 15px;

      border-right: solid transparent 2px;

      &.Selected {
        border-right: solid var(--wavy-blue) 2px;
      }

      &.Selected {
        font-weight: var(--bold-weight);
      }
    }


    @media only screen and (max-width: 768px) {
      text-align: center;
      padding-top: 15px;

      a {
        display: inline-block;
        padding: 0;
        margin-right: 20px;
        margin-left: 20px;
        border-bottom: solid transparent 2px;

        &.Selected, &:hover {
          border-right: solid transparent 2px;
          border-bottom-color: var(--wavy-blue);
        }
      }
    }
  }

  .SettingsCard {
    flex-grow: 1;

    .BarLoader {
      position: relative;
      height: 0;
    }

    .SubPage {
      padding: 30px;
    }

    .Title {
      display: inline-block;
      font-size: 30px;
      font-weight: var(--bold-weight);
      border-bottom: solid var(--wavy-blue) 2px;
    }

    .Content {
      margin-top: 30px;
    }

    .Section {
      display: flex;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 35px;
      }
    }

    .DescriptionLogo {
      width: 38px;
      min-width: 38px;
      height: 38px;
      margin-right: 19px;
    }

    .DescriptionParent {
      min-width: 375px;
      vertical-align: middle;

      @media only screen and (max-width: 768px) {
        min-width: 0;
        flex-grow: 1;
      }

      .DescriptionTitle {
        font-size: 18px;
        margin-bottom: 2px;
      }

      .Description {
        color: var(--light-text);
        padding-right: 50px;
      }

      .DescriptionPrimary {
        color: var(--wavy-blue);
        padding-right: 50px;
      }
    }

    .ButtonParent {
      text-align: center;
      display: flex;
      align-items: center;

      > div:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
}
