

//@media only screen and (min-width: 769px) {
//}
//@media only screen and (max-width: 768px) {
//}

.UserListenHighlights {
  @media only screen and (min-width: 769px) {
    height: 150px;
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 768px) {
    margin: 20px 0;
    flex-grow: 1;
  }
}

.ListenIcon {
  @media only screen and (min-width: 769px) {
    width: 24px;
    height: 25px;
    background-size: 24px 24px;
    margin-left: 10px;
    transform: scale(0.9);
    background-repeat: no-repeat;
    filter: var(--icon-brightness);
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.NoHistory {
  @media only screen and (min-width: 769px) {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: var(--light-text);
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    color: var(--light-text);
    padding: 0 10px;
    box-sizing: border-box;
  }
}

.WithHistory {
  @media only screen and (min-width: 769px) {
    display: flex;
    width: 100%;
    align-items: center;
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    align-items: center;
  }
}

.LastSongColumn {
  @media only screen and (min-width: 769px) {
    display: flex;
    padding: 0 15px;
    box-sizing: border-box;
    align-items: center;
    flex-grow: 1;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.AlbumCover {
  height: 120px;
  width: 120px;
  min-width: 120px;
  min-height: 120px;
}

.LastSongDescription {
  margin-left: 15px;
}

.LastSongName {
  font-size: 24px;
  font-weight: var(--bold-weight);
  margin-bottom: 5px;
  word-break: break-word;
}

.LastSongArtist {
  font-size: 20px;
  margin-bottom: 5px;
}

.LastSongTime {
  font-size: 16px;
  font-weight: var(--bold-weight);
  color: var(--light-text);

  &.Live {
    color: var(--accent-pink);
    display: flex;
    align-items: baseline;
  }

  .LivePulse {
    margin-left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--accent-pink);

    animation-name: pulse;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;

    $max-pulse-radius: 8px;

    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 var(--accent-pink);
      }

      70% {
        box-shadow: 0 0 0 $max-pulse-radius var(--accent-pink-transparent);
      }

      100% {
        box-shadow: 0 0 0 $max-pulse-radius var(--accent-pink-transparent);
      }
    }
  }
}

.SongCount {
  text-decoration: none;
  color: var(--default-text);

  @media only screen and (min-width: 769px) {
    align-items: center;
    padding: 0 20px;
    min-width: 100px;
  }
  @media only screen and (max-width: 768px) {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-right: 50px;
  }
}

.FriendCount {
  @media only screen and (min-width: 769px) {
    display: none;
  }
}

.CountTitle {
  @media only screen and (min-width: 769px) {
    font-size: 22px;
    color: var(--light-text);
    font-weight: normal;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    color: var(--light-text);
    font-weight: normal;
  }
}

.CountNumber {
  @media only screen and (min-width: 769px) {
    font-size: 24px;
    font-weight: var(--bold-weight);
    display: inline-flex;
    align-items: center;
  }
  @media only screen and (max-width: 768px) {
    font-size: 24px;
    font-weight: var(--bold-weight);
    color: var(--default-text);
  }
}

.Loading {
  @media only screen and (min-width: 769px) {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    opacity: 0.5;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
}
